import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en/translations.json";
import translationDe from "./locales/de/translations.json";
import translationFr from "./locales/fr/translations.json";
import translationHu from "./locales/hu/translations.json"

const resources = {
  de: {
    translation: translationDe,
  },
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
  hu: {
    translation: translationHu
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["en", "de", "fr", "hu"],
  });

export default i18n;
