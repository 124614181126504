import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase";
import reportWebVitals from "./reportWebVitals";
import config from "./firebase/config";
import root from "./store/reducers/root";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./i18n";
import "./Css/index.css";
import "./Css/responsive.css";

firebase.initializeApp(config);
firebase.analytics();

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://gql.daheimladen.com/query",
    retryAttempts: 10,
    connectionParams: async () => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
  })
);

const client = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache(),
});

const middlewares = [thunk.withExtraArgument(getFirebase)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;

const store = createStore(
  root,
  composeEnhancers(applyMiddleware(...middlewares))
);

const rrfConfig = {};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <ApolloProvider client={client}>
            <Suspense fallback={<CircularProgress />}>
              <App />
            </Suspense>
          </ApolloProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
