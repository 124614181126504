import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  useTheme,
  keyframes,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { WbSunny } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getChargingStations,
  getLastSettledTransaction,
  getLatestInProgressTransaction,
  getChargingStationPowerUtilization,
  getLatestStatus,
} from "../../store/actions/chargingStation";
import moment from "moment-timezone";
import "moment/locale/de";
import { getFirebase } from "react-redux-firebase";
import { useSubscription, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getMeterPowerfoxRQ,
  remoteStart,
  remoteStop,
  updatePVMode,
} from "../../lib/api";
import LoadingButton from "@mui/lab/LoadingButton";
import toast, { Toaster } from "react-hot-toast";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { classesx as classesxReport } from "./Report";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  IconButtonRoot as IconButtonRootReport,
} from "./Report";
import { styled } from "@mui/material/styles";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import FilterDramaRoundedIcon from "@mui/icons-material/FilterDramaRounded";
import { LoadingCharging } from "../common/loading_placeholder/Loading";
import Switch from "@mui/material/Switch";
import Background from "../common/background/background";
import { useContext } from "react";
import { SelectedStationContext } from "../contexts/selectedStation";

const spin = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
});

export const PREFIX = "Dashboard";
export const DashboarClasses = {
  Input_Wrapper: `${PREFIX}-Input_Wrapper`,
  GridRoot_container: `${PREFIX}-GridRoot_container`,
  cards_row: `${PREFIX}-cards_row`,
  Col_4: `${PREFIX}-Col_4`,
  Col_6: `${PREFIX}-Col_6`,
  Col_12: `${PREFIX}-Col_12`,
  common_card_body: `${PREFIX}-common_card_body`,
  MB_30px: `${PREFIX}-MB_30px`,
  cards_row_First: `${PREFIX}-cards_row_First`,
  cards_row_First_item3: `${PREFIX}-cards_row_First_item3`,
  cards_row_First_item2: `${PREFIX}-cards_row_First_item2`,
  cards_row_First_item1: `${PREFIX}-cards_row_First_item1`,
  Dashboard_card_h3: `Dashboard_card_h3`,
  Dashboard_card_kw: `Dashboard_card_kw`,
  Status_flex: `Status_flex`,
  CONSUMPTION_price: `CONSUMPTION_price`,
  Charge_percentage: `Charge_percentage`,
  last_charging_Bottom: `last_charging_Bottom`,
  last_charging_Top: `last_charging_Top`,
  last_charging_Left: `last_charging_Left`,
  last_charging_Right: `last_charging_Right`,
  time_text: `time_text`,
  comman_label: `comman_label`,
  section_1: `${PREFIX}-section_1`,
  section_2: `${PREFIX}-section_2`,
  Section_2_head: `${PREFIX}-Section_2_head`,
  cards_row_Second: `${PREFIX}-cards_row_Second`,
  cards_row_Second_item1: `${PREFIX}-cards_row_Second_item1`,
  cards_row_Second_item2: `${PREFIX}-cards_row_Second_item2`,
  cards_row_Second_item3: `${PREFIX}-cards_row_Second_item3`,
  cards_row_Second_item4: `${PREFIX}-cards_row_Second_item4`,
  Status_Icon: `Status_Icon`,
  LoadingButtonRoot: `${PREFIX}-LoadingButtonRoot`,
  Stop_charging_btn_block: `${PREFIX}-Stop_charging_btn_block`,
  MenuItemRoot: `${PREFIX}-MenuItemRoot`,
};

export const DashboardDiv = styled("div")(({ theme }) => ({
  [`&.${DashboarClasses.Input_Wrapper}`]: {
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 51px)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 44px)",
    },
  },
  [`&.${DashboarClasses.cards_row}`]: {
    width: "calc(100% + 30px)",
    margin: "0 -15px",
    display: "flex",
    flexWrap: "wrap",
    // alignItems: "center",
  },
  [`&.${DashboarClasses.Col_4}`]: {
    padding: "0 15px",
    width: "33.33%",
  },
  [`&.${DashboarClasses.Col_6}`]: {
    padding: "0 15px",
    width: "50%",
  },
  [`&.${DashboarClasses.Col_12}`]: {
    padding: "0 15px",
    width: "100%",
  },
  [`&.${DashboarClasses.common_card_body}`]: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "35px",
    padding: "30px 35px",
    minHeight: "260px",
    [theme.breakpoints.down("xl")]: {
      padding: "25px",
      borderRadius: "25px",
      minHeight: "250px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
      borderRadius: "20px",
      minHeight: "215px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
      borderRadius: "10px",
    },
  },
  [`&.${DashboarClasses.MB_30px}`]: {
    marginBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },
  [`&.${DashboarClasses.cards_row_First_item1}`]: {
    [`& .Dashboard_card_h3`]: {
      margin: "20px 0 30px 0",
      [theme.breakpoints.down("lg")]: {
        margin: "20px 0 20px 0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "10px 0 15px 0",
      },
    },
  },
  [`&.${DashboarClasses.cards_row_First_item2}`]: {
    [`& .${DashboarClasses.Status_flex} .Status_span:last-child`]: {
      fontWeight: 400,
      textTransform: "capitalize",
    },
  },
  [`&.${DashboarClasses.Status_flex}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [`& .Status_span`]: {
      fontWeight: 500,
      fontSize: "15px",
      color: "#B3B3B3",
      textTransform: "uppercase",
      lineHeight: 1.7,
      fontFamily: "'Roboto', sans-serif",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  },
  [`&.${DashboarClasses.last_charging_Top}`]: {
    marginTop: "18px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "14px",
    },
  },
  [`&.${DashboarClasses.last_charging_Bottom}`]: {
    marginTop: "15px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "12px",
    },
  },
  [`&.${DashboarClasses.Status_Icon}`]: {
    margin: "-5px -5px 0 0",
  },
  [`&.${DashboarClasses.cards_row_Second_item1}`]: {
    [`& .Dashboard_card_h3`]: {
      margin: "20px 0 40px 0",
      [theme.breakpoints.down("lg")]: {
        margin: "20px 0 20px 0",
      },
    },
    [`& .Dashboard_card_h3 + .Status_flex `]: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "baseline",
        [`& .Current_Charging_Power_btn`]: {
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        [`& .Current_Charging_Power_btn`]: {
          marginTop: "15px",
        },
      },
    },
  },
  [`&.${DashboarClasses.cards_row_Second_item2}`]: {
    [`& .last_updated p`]: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.4,
      color: "#298D74",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
  },
  [`&.${DashboarClasses.cards_row_Second_item3}`]: {
    [`& .card_6_text`]: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.5,
      color: "#646464",
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    [`& .Bottom_Block`]: {
      marginTop: "20px",
      [theme.breakpoints.down("lg")]: {
        marginTop: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    [`& .Status_flex:nth-of-type(2)`]: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "baseline",
        [`& .Top_Block`]: {
          marginTop: "12px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        [`& .Top_Block`]: {
          marginTop: "10px",
        },
      },
    },
  },
  [`&.${DashboarClasses.last_charging_Left}`]: {
    marginRight: "10px",
  },
  [`&.${DashboarClasses.Stop_charging_btn_block}`]: {
    paddingTop: "45px",
    textAlign: "right",
    [theme.breakpoints.down("xl")]: {
      paddingTop: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px",
    },
    [`& .Stop_charging_btn`]: {
      outline: "unset",
      border: "unset",
      padding: "5px",
      backgroundColor: "#fff",
      boxShadow: " 0px 1px 5px rgb(0 0 0 / 25%)",
      borderRadius: "25px",
      textAlign: "right",
      display: "inline-block",
      cursor: "pointer",
      fontFamily: "'Roboto'",
      transition: "all 0.4s cubic-bezier(0.19, 1, 0.22, 1)",
      [`& .stop_charging_flex`]: {
        background: "#298D74",
        borderRadius: "25px",
        display: "flex",
        alignItems: "center",
        padding: "4px 12px 4px 4px",
        transition: "all 0.4s cubic-bezier(0.19, 1, 0.22, 1)",
        [`& span`]: {
          textTransform: "uppercase",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: 1.5,
          color: "#FFFFFF",
          transition: "all 0.4s cubic-bezier(0.19, 1, 0.22, 1)",
        },
        [`& p`]: {
          background: "#FFFFFF",
          boxShadow:
            "0px 0px 4px rgb(0 0 0 / 25%), inset 0px 4px 4px rgb(0 0 0 / 14%)",
          borderRadius: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "31px",
          height: "31px",
          marginRight: "5px",
          transition: "all 0.4s cubic-bezier(0.19, 1, 0.22, 1)",
        },
      },
      [`&:hover`]: {
        backgroundColor: "#298D74",
        [`& .stop_charging_flex`]: {
          background: "#fff",
          [`& span`]: {
            color: "#298D74",
            fontWeight: 600,
            textShadow: "0px 0px 5px #298d7482",
          },
          [`& p`]: {
            background: "#298D74",
          },
          [`& .MuiSvgIcon-root`]: {
            color: "#fff",
          },
        },
      },
    },
  },
}));

export const MenuItemRoot = styled(MenuItem)(({ theme }) => ({
  [`&.${DashboarClasses.MenuItemRoot}`]: {
    lineHeight: "normal",
    fontWeight: 500,
    padding: "10px 5px 10px 10px",
    minHeight: "unset",
    [theme.breakpoints.down("xl")]: {
      padding: "8px 5px 8px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px 8px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

export const LoadingButtonRoot = styled(LoadingButton)(({ theme }) => ({
  [`&.${DashboarClasses.LoadingButtonRoot}`]: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: 1.2,
    padding: "10px 27px",
    borderRadius: "35px",
    overflow: "hidden",
    textTransform: "capitalize",
    letterSpacing: "unset",
    [theme.breakpoints.down("xs")]: {
      padding: "9px 18px",
      fontSize: "16px",
    },
  },
}));

export const RootH3 = styled("h3")(({ theme }) => ({
  [`&.${DashboarClasses.Dashboard_card_h3}`]: {
    fontWeight: 400,
    fontSize: "25px",
    color: "#298D74",
    fontFamily: "'Roboto', sans-serif",
    lineHeight: 1.2,
    textTransform: "none",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  [`&.${DashboarClasses.Section_2_head}`]: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: 1.2,
    color: "#B3B3B3",
    textTransform: "uppercase",
    marginBottom: "30px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

export const RootP = styled("p")(({ theme }) => ({
  [`&.${DashboarClasses.Dashboard_card_kw}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: 1.4,
    color: "#646464",
    textTransform: "none",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  [`&.${DashboarClasses.CONSUMPTION_price}`]: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1.5,
    color: "#646464",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  [`&.${DashboarClasses.Charge_percentage}`]: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: 1.5,
    color: "#298D74",
    marginRight: "8px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  [`&.${DashboarClasses.comman_label}`]: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.6,
    color: "#B3B3B3",
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  [`&.${DashboarClasses.time_text}`]: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1.6,
    color: "#646464",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

export const GridRoot = styled(Grid)(({ theme }) => ({
  [`&.${DashboarClasses.GridRoot_container}`]: {
    height: "100%",
    marginBottom: "30px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
}));

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#298D74",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Dashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const { t, i18n } = useTranslation();
  const [chargingButtonText, setChargingButtonText] = useState(
    t("button.start_charging")
  );
  const REMOTE_START_STOP_ACCEPTED = "Accepted";

  var date = new Date();

  const csCtx = useContext(SelectedStationContext);
  const [pvMode, setPVMode] = useState("OFF");
  const [meterPowerfoxUID, setMeterPowerfoxUID] = useState("");
  const [chargingState, setChargingState] = useState("");
  const [connected, setConnected] = useState(false);
  const [consumptionCard, setConsumptionCard] = useState({
    consumption: 0,
    cost: 0,
    change: 0,
  });

  const chargingStationIDs = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const powerUtilization = useSelector(
    (state) => state.chargingStation.powerUtilization
  );

  const lastSettledTransaction = useSelector(
    (state) => state.chargingStation.lastSettledTransaction
  );

  const lastInProgressTransaction = useSelector(
    (state) => state.chargingStation.getLatestInProgressTransaction
  );

  const lastSettledTransactionRequestLoading = useSelector(
    (state) =>
      state.chargingStationRequestState.lastSettledTransactionRequestLoading
  );

  const meterPowerfox = useQuery(["powerfox"], getMeterPowerfoxRQ);
  const changePVMode = useMutation(["powerfox"], updatePVMode);

  const remoteStartMut = useMutation(
    ["cs", csCtx.selectedStation],
    remoteStart
  );
  const remoteStopMut = useMutation(["cs", csCtx.selectedStation], remoteStop);

  const remoteStartToast = (status) => {
    if (status === REMOTE_START_STOP_ACCEPTED)
      return toast.success("Remote Start Accepted");
    else return toast.error("Remote Start Rejected");
  };

  const remoteStopToast = (status) => {
    if (status === REMOTE_START_STOP_ACCEPTED)
      return toast.success("Remote Stop Accepted");
    else return toast.error("Remote Stop Rejected");
  };

  const STATUS_SUBSCRIPTION = gql`
    subscription StatusSubscription($chargingStationID: String!) {
      connectorStatus(chargingStationID: $chargingStationID) {
        connected
        status
        activePowerImport
      }
    }
  `;

  const { data, loading } = useSubscription(STATUS_SUBSCRIPTION, {
    variables: { chargingStationID: csCtx.selectedStation },
  });

  const PV_SUBSCRIPTION = gql`
    subscription PvSubscription($uid: String!) {
      pvStatus(uid: $uid) {
        chargingStationID
        watt
        minExcessPower
        wattReportTimeStamp
      }
    }
  `;

  const pv = useSubscription(PV_SUBSCRIPTION, {
    variables: {
      uid: meterPowerfoxUID,
    },
  });

  const handleToggleChange = (event, newPVMode) => {
    setPVMode(newPVMode);
    if (meterPowerfox.isSuccess && meterPowerfox.data !== "") {
      changePVMode.mutate({ uid: meterPowerfox.data.uid, mode: newPVMode });
    }
  };

  function handleRefresh() {
    if (csCtx.selectedStation !== "") {
      dispatch(getLastSettledTransaction(csCtx.selectedStation));
      meterPowerfox.refetch();
    }
  }

  function fmtTimeDuration(startTime, endTime) {
    var st = new Date(Date.parse(startTime));
    var et = new Date(Date.parse(endTime));
    var duration = et.getTime() - st.getTime();
    var d = new Date(duration);
    var hh = d.getUTCHours();
    var mm = d.getUTCMinutes();
    var ss = d.getUTCSeconds();

    var fmtTime = "";
    if (hh !== 0) {
      fmtTime = `${fmtTime} ${hh}hr`;
    }
    if (mm !== 0) {
      fmtTime = `${fmtTime} ${mm}min`;
    }
    if (ss !== 0) {
      fmtTime = `${fmtTime} ${ss}sec`;
    }
    return fmtTime;
  }

  function GetDate({ time }) {
    var d = new Date(Date.parse(time));

    return (
      <>
        {moment(d)
          .locale("de")
          .tz("Europe/Berlin")
          .format("HH:mm:ss, DD.MM.YY")}
      </>
    );
  }

  function getCurrentPowerAndCostUtilization() {
    if (powerUtilization === null) {
      return { consumption: 0, cost: 0, change: 0 };
    }

    var d = new Date();
    const currentMonth = d.toLocaleString("en", { month: "short" });
    const currentYear = d.getFullYear();
    d.setMonth(d.getMonth() - 1);
    const prevMonth = d.toLocaleString("en", { month: "short" });
    const prevYear = d.getFullYear();

    const currentMonthUtilArr = powerUtilization.filter(
      (res) => res.month === currentMonth && res.year === currentYear
    );
    const prevMonthUtilArr = powerUtilization.filter(
      (res) => res.month === prevMonth && res.year === prevYear
    );
    var currentMonthUtil;
    var prevMonthUtil;
    prevMonthUtilArr.length > 0
      ? (prevMonthUtil = prevMonthUtilArr[0])
      : (prevMonthUtil = { power_consumed: 0, cost: 0 });
    currentMonthUtilArr.length > 0
      ? (currentMonthUtil = currentMonthUtilArr[0])
      : (currentMonthUtil = { power_consumed: 0, cost: 0 });

    return {
      consumption: currentMonthUtil.power_consumed,
      cost: currentMonthUtil.cost,
      change: Math.round(
        prevMonthUtil.power_consumed !== 0
          ? ((currentMonthUtil.power_consumed - prevMonthUtil.power_consumed) /
              prevMonthUtil.power_consumed) *
              100
          : 0
      ),
    };
  }

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (!meterPowerfox.isLoading && meterPowerfox.isSuccess) {
      setPVMode(meterPowerfox.data.pv_mode);
      setMeterPowerfoxUID(meterPowerfox.data.uid);
    }
  }, [meterPowerfox.isLoading, meterPowerfox.data]);

  useEffect(() => {
    if (!loading && data !== undefined && csCtx.selectedStation !== "") {
      setChargingState(data.connectorStatus.status);
      setConnected(data.connectorStatus.connected);
      dispatch(getLatestInProgressTransaction(csCtx.selectedStation));
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      chargingStationIDs !== null &&
      chargingStationIDs.length > 0 &&
      csCtx.selectedStation === "" || csCtx.selectedStation === "all"
    ) {
      csCtx.setSelectedStation(chargingStationIDs[0].charging_station_id);
    }
  }, [chargingStationIDs]);

  useEffect(() => {
    if (csCtx.selectedStation !== "") {
      dispatch(getLatestStatus(csCtx.selectedStation));
      dispatch(getLatestInProgressTransaction(csCtx.selectedStation));
      dispatch(getChargingStationPowerUtilization(csCtx.selectedStation));
      dispatch(getLastSettledTransaction(csCtx.selectedStation));
    }
  }, [csCtx.selectedStation]);

  useEffect(() => {
    if (csCtx.selectedStation !== "" && remoteStartMut.isSuccess) {
      if (remoteStartMut.data.status === REMOTE_START_STOP_ACCEPTED) {
        setChargingButtonText(t("button.stop_charging"));
      }
      remoteStartToast(remoteStartMut.data.status);
    }
  }, [csCtx.selectedStation, remoteStartMut.isLoading]);

  useEffect(() => {
    if (csCtx.selectedStation !== "" && remoteStopMut.isSuccess) {
      if (remoteStopMut.data.status === REMOTE_START_STOP_ACCEPTED) {
        setChargingButtonText(t("button.start_charging"));
      }
      remoteStopToast(remoteStopMut.data.status);
    }
  }, [csCtx.selectedStation, remoteStopMut.isLoading]);

  useEffect(() => {
    if (!loading && data !== undefined) {
      if (
        data.connectorStatus.status === "Charging" ||
        data.connectorStatus.status === "SuspendedEVSE"
      ) {
        setChargingButtonText(t("button.stop_charging"));
      } else if (data.connectorStatus.status === "Preparing") {
        setChargingButtonText(t("button.start_charging"));
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (powerUtilization !== null) {
      setConsumptionCard(getCurrentPowerAndCostUtilization());
    }
  }, [powerUtilization]);

  return (
    <div>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRoot
          container
          item
          xs={12}
          className={DashboarClasses.GridRoot_container}
        >
          <GridRoot
            className={DashboarClasses.section_1}
            sx={{ width: "100%" }}
            item
            xs={12}
          >
            <RootDivReport
              className={classesxReport.form_row}
              sx={{
                justifyContent: "space-between",
                marginBottom: "30px",
                [theme.breakpoints.down("lg")]: {
                  alignItems: "self-end!important",
                },
                [theme.breakpoints.down("xs")]: {
                  marginBottom: "20px",
                },
              }}
            >
              <DashboardDiv className={DashboarClasses.Input_Wrapper}>
                <RootDivReport
                  className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      flexDirection: "column",
                      alignItems: "flex-start!important",
                    },
                  }}
                >
                  <Labelh4Report className={classesxReport.labelh4_form}>
                    {`${t("text.charging_station")}`}
                  </Labelh4Report>
                  <RootDivReport className={classesxReport.selct_dropdown}>
                    <FormControlRootReport
                      className={classesxReport.FormControlRoot}
                    >
                      <Select
                        value={csCtx.selectedStation}
                        onChange={(event) =>
                          csCtx.setSelectedStation(event.target.value)
                        }
                        label="Select ChargingStation"
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        {chargingStationIDs !== null &&
                        chargingStationIDs.length > 0
                          ? chargingStationIDs.map((cs) => (
                              <MenuItemRoot
                                className={DashboarClasses.MenuItemRoot}
                                key={cs.unqid}
                                value={cs.charging_station_id}
                              >
                                {cs.alias || cs.charging_station_id}
                              </MenuItemRoot>
                            ))
                          : null}
                      </Select>
                    </FormControlRootReport>
                  </RootDivReport>
                </RootDivReport>
              </DashboardDiv>
              <IconButtonRootReport
                aria-label="refresh"
                className={`${classesxReport.IconButtonRoot}`}
                onClick={handleRefresh}
                size="large"
              >
                <RefreshOutlinedIcon />
              </IconButtonRootReport>
            </RootDivReport>
            <DashboardDiv
              className={`${DashboarClasses.cards_row} ${DashboarClasses.cards_row_First}`}
            >
              <DashboardDiv
                className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
              >
                <DashboardDiv
                  className={`${DashboarClasses.common_card_body} ${DashboarClasses.cards_row_First_item1}`}
                >
                  <DashboardDiv className={DashboarClasses.Status_flex}>
                    <p className="Status_span">Status</p>
                    <DashboardDiv className={DashboarClasses.Status_Icon}>
                      {data !== undefined && data.connectorStatus.connected ? (
                        <>
                          <Tooltip title="Online">
                            <FilterDramaRoundedIcon sx={{ color: "#298D74" }} />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Offline">
                            <CloudOffRoundedIcon color="error" />
                          </Tooltip>
                        </>
                      )}
                    </DashboardDiv>
                  </DashboardDiv>
                  <RootH3 className={DashboarClasses.Dashboard_card_h3}>
                    {!loading ? (
                      data !== undefined &&
                      data.connectorStatus.status !== "" ? (
                        t(`server_val.${data.connectorStatus.status}`)
                      ) : (
                        t("text.status_unavailable")
                      )
                    ) : (
                      <Skeleton />
                    )}
                  </RootH3>
                  <RootP className={DashboarClasses.Dashboard_card_kw}>
                    {!loading ? (
                      data !== undefined ? (
                        Number(
                          data.connectorStatus.activePowerImport
                        ).toLocaleString(i18n.language, {
                          minimumFractionDigits: 2,
                        }) + " kW"
                      ) : null
                    ) : (
                      <Skeleton />
                    )}
                  </RootP>
                  <LoadingButton
                    sx={{
                      textTransform: "none",
                      borderRadius: "35px",
                      width: "100%",
                      marginTop: "20px",
                    }}
                    color="primary"
                    variant="contained"
                    loading={
                      remoteStartMut.isLoading || remoteStopMut.isLoading
                    }
                    disabled={
                      !connected ||
                      loading ||
                      (chargingState !== "Preparing" &&
                        chargingState !== "Charging" &&
                        chargingState !== "SuspendedEVSE")
                    }
                    onClick={() => {
                      if (chargingButtonText === t("button.start_charging")) {
                        remoteStartMut.mutate({
                          chargingStationID: csCtx.selectedStation,
                        });
                      } else if (
                        chargingButtonText === t("button.stop_charging")
                      ) {
                        remoteStopMut.mutate({
                          chargingStationID: csCtx.selectedStation,
                          transactionID:
                            lastInProgressTransaction.transaction_id,
                        });
                      }
                    }}
                  >
                    {chargingButtonText}
                  </LoadingButton>
                </DashboardDiv>
              </DashboardDiv>
              {meterPowerfox.data &&
              meterPowerfox.data.charging_station_id ===
                csCtx.selectedStation ? (
                <DashboardDiv
                  className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
                >
                  <DashboardDiv
                    className={`${DashboarClasses.common_card_body} ${DashboarClasses.cards_row_First_item3}`}
                  >
                    <DashboardDiv className={DashboarClasses.Status_flex}>
                      <p className="Status_span">{t("text.pv_charging")}</p>
                      <WbSunny
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          color:
                            pv.data !== undefined && pv.data.pvStatus.watt < 0
                              ? "yellowgreen"
                              : "gray",
                          animation:
                            pv.data !== undefined && pv.data.pvStatus.watt < 0
                              ? `${spin} infinite 15s linear`
                              : null,
                        }}
                      />
                    </DashboardDiv>
                    <DashboardDiv>
                      {pv.loading ? (
                        <LoadingCharging />
                      ) : (
                        <>
                          <DashboardDiv
                            className={DashboarClasses.last_charging_Left}
                          >
                            <DashboardDiv
                              className={DashboarClasses.last_charging_Top}
                            >
                              <RootH3
                                className={DashboarClasses.Dashboard_card_h3}
                                sx={{
                                  color: `${
                                    pv.data !== undefined &&
                                    pv.data.pvStatus.watt <= 0
                                      ? "green"
                                      : "red"
                                  }!important`,
                                }}
                              >
                                {pv.data !== undefined
                                  ? Number(
                                      pv.data.pvStatus.watt
                                    ).toLocaleString(i18n.language, {
                                      minimumFractionDigits: 2,
                                    })
                                  : 0}{" "}
                                kW
                              </RootH3>
                              <RootP className={DashboarClasses.comman_label}>
                                {pv.data !== undefined &&
                                pv.data.pvStatus.watt > 0
                                  ? t("text.grid_purchase")
                                  : t("text.grid_feed_in")}
                              </RootP>
                            </DashboardDiv>
                            <DashboardDiv sx={{ marginTop: "10px" }}>
                              {meterPowerfox.isLoading ? (
                                <Skeleton />
                              ) : (
                                <ToggleButtonGroup
                                  color="primary"
                                  value={pvMode}
                                  fullWidth
                                  disabled={changePVMode.isLoading}
                                  onChange={handleToggleChange}
                                  exclusive={true}
                                >
                                  <ToggleButton value="OFF">
                                    {t("button.off")}
                                  </ToggleButton>
                                  <ToggleButton value="PV_ONLY">
                                    {t("button.pv")}
                                  </ToggleButton>
                                  <ToggleButton
                                    value="PV_SUPPORTED"
                                    sx={{ textTransform: "none" }}
                                  >
                                    {t("button.pv_supported")}
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              )}
                            </DashboardDiv>
                          </DashboardDiv>
                          <DashboardDiv sx={{ marginTop: "10px" }}>
                            <RootP className={DashboarClasses.comman_label}>
                              <Typography
                                variant="caption"
                                sx={{ color: "#808080" }}
                                gutterBottom
                              >
                                {pv.data !== undefined
                                  ? `${t("text.last_updated")} ${moment
                                      .utc(pv.data.pvStatus.wattReportTimeStamp)
                                      .local()
                                      .locale(i18n.language)
                                      .fromNow()}`
                                  : t("text.last_updated_time_not_available")}
                              </Typography>
                            </RootP>
                          </DashboardDiv>
                        </>
                      )}
                    </DashboardDiv>
                  </DashboardDiv>
                </DashboardDiv>
              ) : null}
              <DashboardDiv
                className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
              >
                <DashboardDiv
                  className={`${DashboarClasses.common_card_body} ${DashboarClasses.cards_row_First_item2}`}
                >
                  <DashboardDiv className={DashboarClasses.Status_flex}>
                    <p className="Status_span">{t("text.consumption")}</p>
                    <p className="Status_span">
                      {date.toLocaleString(i18n.language, { month: "long" })}
                    </p>
                  </DashboardDiv>
                  <DashboardDiv
                    className={DashboarClasses.Status_flex}
                    sx={{
                      margin: "18px 0 32px 0",
                      [theme.breakpoints.down("lg")]: {
                        margin: "16px 0 16px 0",
                      },
                    }}
                  >
                    <RootH3
                      className={DashboarClasses.Dashboard_card_h3}
                      sx={{ color: "#646464!important" }}
                    >
                      {Number(consumptionCard.consumption).toLocaleString(
                        i18n.language
                      )}{" "}
                      kWh
                    </RootH3>
                    <RootP className={DashboarClasses.CONSUMPTION_price}>
                      {Number(consumptionCard.cost).toLocaleString(
                        i18n.language
                      )}{" "}
                      €
                    </RootP>
                  </DashboardDiv>
                  <DashboardDiv
                    className={DashboarClasses.Status_flex}
                    sx={{
                      justifyContent: "start!important",
                      lineHeight: 1.66,
                      color: "#B3B3B3",
                      marginBottom: "8px",
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px",
                        marginBottom: "5px",
                      },
                      [theme.breakpoints.down("xs")]: {
                        fontSize: "14px",
                        marginBottom: "8px",
                      },
                    }}
                  >
                    <RootP className={DashboarClasses.Charge_percentage}>
                      {consumptionCard.change > 0
                        ? "+" + consumptionCard.change
                        : consumptionCard.change}
                      %
                    </RootP>
                    {t("text.since_last_month")}
                  </DashboardDiv>
                </DashboardDiv>
              </DashboardDiv>
              <DashboardDiv
                className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
              >
                <DashboardDiv
                  className={`${DashboarClasses.common_card_body} ${DashboarClasses.cards_row_First_item3}`}
                >
                  <DashboardDiv className={DashboarClasses.Status_flex}>
                    <p className="Status_span">
                      {t("text.last_charging_session")}
                    </p>
                  </DashboardDiv>
                  <DashboardDiv className={DashboarClasses.Status_flex}>
                    {lastSettledTransactionRequestLoading !== null &&
                    lastSettledTransactionRequestLoading === true ? (
                      <LoadingCharging />
                    ) : (
                      <>
                        <DashboardDiv
                          className={DashboarClasses.last_charging_Left}
                        >
                          <DashboardDiv
                            className={DashboarClasses.last_charging_Top}
                          >
                            <RootH3
                              className={DashboarClasses.Dashboard_card_h3}
                              sx={{ color: "#646464!important" }}
                            >
                              {lastSettledTransaction !== null
                                ? `${Number(
                                    lastSettledTransaction.total_utilization /
                                      1000
                                  ).toLocaleString(i18n.language, {
                                    minimumFractionDigits: 2,
                                  })} kWh / ${Number(
                                    lastSettledTransaction.cost
                                  ).toLocaleString(i18n.language, {
                                    minimumFractionDigits: 2,
                                  })} €`
                                : null}
                            </RootH3>
                            <RootP className={DashboarClasses.comman_label}>
                              {t("text.last_power_consumed")}
                            </RootP>
                          </DashboardDiv>

                          <DashboardDiv
                            className={DashboarClasses.last_charging_Bottom}
                          >
                            <div className={DashboarClasses.time_text}>
                              {lastSettledTransaction !== null ? (
                                <GetDate
                                  time={
                                    lastSettledTransaction.transaction_start_at
                                  }
                                />
                              ) : null}
                            </div>
                            <RootP className={DashboarClasses.comman_label}>
                              {t("text.last_charging_started_at")}
                            </RootP>
                          </DashboardDiv>
                        </DashboardDiv>

                        <DashboardDiv
                          className={DashboarClasses.last_charging_Right}
                        >
                          <DashboardDiv
                            className={DashboarClasses.last_charging_Top}
                          >
                            <RootH3
                              className={DashboarClasses.Dashboard_card_h3}
                              sx={{ color: "#646464!important" }}
                            >
                              {lastSettledTransaction !== null
                                ? fmtTimeDuration(
                                    lastSettledTransaction.transaction_start_at,
                                    lastSettledTransaction.transaction_end_at
                                  )
                                : 0}
                            </RootH3>
                            <RootP className={DashboarClasses.comman_label}>
                              {t("text.last_charging_duration")}
                            </RootP>
                          </DashboardDiv>

                          <DashboardDiv
                            className={DashboarClasses.last_charging_Bottom}
                          >
                            <RootP className={DashboarClasses.time_text}>
                              {lastSettledTransaction !== null ? (
                                <GetDate
                                  time={
                                    lastSettledTransaction.transaction_end_at
                                  }
                                />
                              ) : null}
                            </RootP>
                            <RootP className={DashboarClasses.comman_label}>
                              {t("text.last_charging_ended_at")}
                            </RootP>
                          </DashboardDiv>
                        </DashboardDiv>
                      </>
                    )}
                  </DashboardDiv>
                </DashboardDiv>
              </DashboardDiv>
            </DashboardDiv>
          </GridRoot>
        </GridRoot>
        <Toaster />
      </RootDivReport>
    </div>
  );
}

export default Dashboard;
