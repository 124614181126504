import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const PREFIX = "history";
const classesx = {
  Card_Col_12: `${PREFIX}-Card_Col_12`,
};

const RootDiv = styled("div")(({ theme }) => ({
  [`&.${classesx.Card_Col_12}`]: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
    borderRadius: "25px",
    padding: "20px 0",
    margin: "0 0 20px 0",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "15px",
      margin: "0 0 15px 0",
      padding: "15px 0",
      [`& .Timing_Panel .teble_card_p`]: {
        width: "100%",
      },
      [`& .Timing_Panel .id_flex`]: {
        flexWrap: "wrap",
      },
      [`& .Timing_Panel .id_flex > h6`]: {
        textAlign: "right",
        width: "100%",
        marginTop: "6px",
      },
    },
    [`& .teble_card_padding`]: {
      padding: "0 20px",
      [theme.breakpoints.down("xs")]: {
        padding: "0 15px",
      },
      [`& .id_flex`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [`& .teble_card_p span`]: {
          fontSize: "14px",
          lineHeight: 1,
          textTransform: "none",
          fontWeight: 500,
        },
        [`& .teble_card_p span:first-of-type`]: {
          color: "#B3B3B3",
        },
        [`& .teble_card_p span:last-child`]: {
          color: "#646464",
        },
        [`& > h6 `]: {
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: 1.6,
          color: "#298D74",
          textTransform: "capitalize",
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
          },
        },
      },
      [`& > .teble_card_p`]: {
        margin: "8px 0",
        [`& span`]: {
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: 1,
          textTransform: "none",
        },
        [`& span:first-of-type`]: {
          color: "#B3B3B3",
        },
        [`& span:last-child`]: {
          color: "#646464",
        },
      },
    },
    [`& .kwh_main`]: {
      background: "#F4F9F8",
      padding: "14px 20px",
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        padding: "12px 15px",
      },
      [`& .kwh_text`]: {
        width: "50%",
        [`& h4`]: {
          fontWeight: 400,
          fontSize: "28px",
          lineHeight: 1.3,
          color: " #646464",
          [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
          },
        },
        [`& p , & > div p`]: {
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: 1.9,
          color: "#B3B3B3",
          textTransform: "none",
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
          },
        },
        [`& > div`]: {
          marginTop: "16px",
        },
        [`& > div span`]: {
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: 1.5,
          color: "#646464",
          [theme.breakpoints.down("xs")]: {
            fontSize: "16px",
          },
        },
      },
    },
  },
}));
export const TransactionsCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <RootDiv className={classesx.Card_Col_12}>
        <div className="teble_card_padding">
          <div className="id_flex">
            <p className="teble_card_p">
              <span>{t("text.id_tag")} : </span>
              <span>{data.idTag}</span>
            </p>
            <h6>{data.txState}</h6>
          </div>
          <p className="teble_card_p">
            <span>{t("text.transaction_id")} : </span>
            <span>{data.txID}</span>
          </p>
        </div>
        <div className="kwh_main">
          <div className="kwh_text">
            <h4>{data.meterStart}</h4>
            <p>{t("text.meter_start")}</p>
            <div>
              <span>{data.powerUsed}</span>
              <p>{t("text.power_used")}</p>
            </div>
          </div>
          <div className="kwh_text">
            <h4>{data.meterStop}</h4>
            <p>{t("text.meter_stop")}</p>
            <div>
              <span>{data.cost}</span>
              <p>{t("text.costs")}</p>
            </div>
          </div>
        </div>
        <div className="teble_card_padding Timing_Panel">
          <p className="teble_card_p">
            <span>{t("text.transaction_start_at")} : </span>
            <span> {data.txStartAt}</span>
          </p>
          <div className="id_flex">
            <p className="teble_card_p">
              <span>{t("text.transaction_stop_at")} : </span>
              <span>{data.txStopAt}</span>
            </p>
            <h6>{data.reason}</h6>
          </div>
        </div>
      </RootDiv>
    </>
  );
};
