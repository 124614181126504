import axios from "axios";
import { getFirebase } from "react-redux-firebase";
import shortid from "shortid";
import {
  GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
  LAST_SETTLED_TRANSACTION_REQUEST_STATE,
  LATEST_STATUS_REQUEST_STATE,
  SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  SET_REMOTE_START_REQUEST_STATE,
  SET_REMOTE_STOP_REQUEST_STATE,
} from "../types/types";
const api = axios.create({
  baseURL: "https://api.daheimladen.com/v1/",
});

export const getLoadBalancers = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get("lb/get_lb", {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var lbs = res.data.loadbalancers;
            lbs.map((lb) => {
              lb.unqid = shortid.generate();
              lb.isLocal = false;
              return lb;
            });

            dispatch({
              type: "GET_LOADBALANCERS_SUCCESS",
              payload: lbs,
            });
          })
          .catch((err) => {
            dispatch({
              type: "GET_LOADBALANCERS_ERROR",
              payload: err,
            });
          });
      });
  };
};

export const createLoadBalancer = (name, max_power, enabled) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const data = {
          name: name,
          max_power: parseFloat(max_power),
          enabled: enabled,
        };
        api
          .post("lb/create_lb", data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var uid = res.data.uid;
            console.log(res);
            dispatch(getLoadBalancers());
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };
};

export const addChargingStationToLB = (chargingStationID, lb_uid) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const data = {
          charging_stations: [chargingStationID],
        };
        api
          .put(`/lb/${lb_uid}/add_station`, data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var uid = res.data.uid;
            console.log(uid);
          });
      });
  };
};

export const updateLoadBalancer = (
  lb_uid,
  name,
  totalAllottedPower,
  enabled
) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const data = {
          name: name,
          enabled: enabled,
          max_power: parseFloat(totalAllottedPower),
        };
        api
          .put(`/lb/${lb_uid}/update_lb`, data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var uid = res.data.uid;
            console.log(uid);
          });
      });
  };
};

export const removeChargingStationFromLB = (lb_uid, chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        const data = {
          charging_stations: [chargingStationID],
        };
        api
          .put(`/lb/${lb_uid}/remove_station`, data, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var uid = res.data.uid;
            console.log(uid);
          });
      });
  };
};

export const deleteLoadBalancer = (lb_uid) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .delete(`/lb/${lb_uid}/delete_lb`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var uid = res.data.uid;
            console.log(uid);
            dispatch(getLoadBalancers());
          });
      });
  };
};
