import "./Css/App.css";
import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AuthIsLoaded from "./components/common/auth_is_loaded/AuthIsLoaded";
import NavBar from "./components/common/navbar/NavBar";
import PrivateRoute from "./components/common/private-route/PrivateRoute";
import SignIn from "./components/logged_out/SignIn";
import SignUp from "./components/logged_out/SignUp";
import Footer from "./components/common/footer/Footer";
import GlobalStyles from "./GlobalStyles";
import SendPasswordResetEmail from "./components/logged_out/SendPasswordResetEmail";
import ResetPassword from "./components/logged_out/ResetPassword";
import AccountSettingsPage from "./components/logged_in/AccountSettingsPage";
import ChargingStationsPage from "./components/logged_in/ChargingStations";
import LoadBalancersList from "./components/logged_in/LoadBalancersList";
import LoadBalancerDetail from "./components/logged_in/LoadBalancerDetail";
import PVSettings from "./components/logged_in/PVSettings";
import { createTheme, ThemeProvider } from "@mui/material";
import { Sidebar } from "./components/common/sidebar/Sidebar";
import { StyledEngineProvider } from "@mui/material/styles";
import Report from "./components/logged_in/Report";
import Dashboard from "./components/logged_in/Dashboard";
import "./Css/App.css";
import Graph from "./components/logged_in/Graph";
import Settings from "./components/logged_in/Settings";
import { useDispatch } from "react-redux";
import { getFirebase } from "react-redux-firebase";
import { SelectedStationContext } from "./components/contexts/selectedStation";
import { useState } from "react";
import { getChargingStations } from "./store/actions/chargingStation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2B8D74",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 374.05,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const [selectedStation, setSelectedStation] = useState("");

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className="App main_wrapper">
          <GlobalStyles />
          <AuthIsLoaded>
            <SelectedStationContext.Provider
              value={{
                selectedStation: selectedStation,
                setSelectedStation: setSelectedStation,
              }}
            >
              <React.Fragment>
                <Router>
                  <Sidebar />
                  <NavBar />
                  <Switch>
                    <Route exact path="/" component={SignIn} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route
                      exact
                      path="/send_password_reset_email"
                      component={SendPasswordResetEmail}
                    />
                    <Route
                      exact
                      path="/__/reset_password"
                      component={ResetPassword}
                    />
                    <Route path="/signout">
                      <Redirect to="/signin" />
                    </Route>
                    <PrivateRoute
                      exact
                      path="/account-settings"
                      component={AccountSettingsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/charging-stations"
                      component={ChargingStationsPage}
                    />
                    <PrivateRoute exact path="/pv" component={PVSettings} />
                    <PrivateRoute
                      exact
                      path="/vlb"
                      component={LoadBalancersList}
                    />
                    <PrivateRoute
                      exact
                      path="/vlb/:uid"
                      component={LoadBalancerDetail}
                    />
                    <PrivateRoute exact path="/report" component={Report} />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute exact path="/analytics" component={Graph} />
                    <PrivateRoute exact path="/settings" component={Settings} />
                  </Switch>
                  <Footer />
                </Router>
              </React.Fragment>
            </SelectedStationContext.Provider>
          </AuthIsLoaded>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
export default App;
