import { createStyles, makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const GlobalStyles = () => {
  const auth = useSelector((state) => state.firebase.auth);
  const useStyles = makeStyles(() =>
    createStyles({
      "@global": {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          "-webkit-print-color-adjust": "exact",
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
          height: "100%",
          width: "100%",
        },
        body: {
          backgroundColor: `${auth.uid ? "#fff" : "#2B8D75"}`,
          height: "100%",
          width: "100%",
          margin: "0",
          padding: "0 !important",
          overflow: "unset !important",
          color: "#000",
          fontSize: "16px",
          fontFamily: " 'Roboto', sans-serif !important ",
          lineHeight: 1.4,
          scrollBehavior: "smooth",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },
      },
    })
  );

  useStyles();

  return null;
};

export default GlobalStyles;
