import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo_sm from "../../../assets/Images/logo-sm.png";
import backgroundsidebar from "../../../assets/Images/sidebar_bg.png";
import carlogo from "../../../assets/Images/car.png";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";

const PREFIX = "sidebar";
const classesx = {
  closebtn: `${PREFIX}-closebtn`,
  logo_wrapper: `${PREFIX}-logo_wrapper`,
  logo_wrapper_mobile_view: `${PREFIX}-logo_wrapper_mobile_view`,
  navText: `${PREFIX}-navText`,
  navlinktab: `${PREFIX}-navlinktab`,
  WrappperSidebar: `${PREFIX}-WrappperSidebar`,
  html_shadow: `${PREFIX}-html_shadow`,
  sidebar_wrapper: `${PREFIX}-sidebar_wrapper`,
};

const RootDiv = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  [`&.${classesx.sidebar_wrapper}`]: {
    [`&.open`]: {
      [`& .MuiDrawer-paper`]: {
        [theme.breakpoints.down("md")]: {
          left: 0,
        },
      },
      [`& .sidebar-html_shadow`]: {
        [theme.breakpoints.down("md")]: {
          display: "block",
          opacity: "1",
          visibility: "visible",
          touchAction: "auto",
          pointerEvents: "all",
        },
      },
    },
  },
  [`&.${classesx.closebtn}`]: {
    margin: "15px 0 0 15px",
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  [`&.${classesx.logo_wrapper}`]: {
    padding: "46px 26px 63px 26px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "55px 0px 55px 0px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "45px 0px 45px 0px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
      visibility: "hidden",
    },
    [`& a `]: {
      width: "100%",
    },
    [`& a img`]: {
      [theme.breakpoints.down("xl")]: {
        width: "82%",
      },
    },
  },
  [`&.${classesx.logo_wrapper_mobile_view}`]: {
    margin: "10px 0 25px 0",
    textAlign: "center",
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0 15px 0",
    },
    "& a": {
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "75px",
      },
    },
  },
  [`&.${classesx.html_shadow}`]: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.562)",
      width: "100%",
      height: "100%",
      left: "0",
      top: "0",
      zIndex: "1303",
      opacity: "0",
      visibility: "hidden",
      touchAction: "none",
      pointerEvents: "none",
      transition: "all 0.4s ease",
    },
  },
}));

const BoxRoot = styled(Box)(({ theme }) => ({
  [`&.${classesx.WrappperSidebar}`]: {
    overflow: "auto",
    [`&::-webkit-scrollbar`]: {
      width: "5px",
    },
    [`&::-webkit-scrollbar-track`]: {
      background: "#eee",
    },
    [`&::-webkit-scrollbar-thumb `]: {
      background: "#237560",
      borderRadius: "10px",
    },
  },
}));

const ListItemTextRoot = styled(ListItemText)(({ theme }) => ({
  boxSizing: "border-box",
  [`&.${classesx.navText}`]: {
    margin: "0 0 0 16px",
    "& .MuiTypography-root": {
      color: "#7D8885",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      fontFamily: " 'Roboto', sans-serif ",
      lineHeight: 1,
      [theme.breakpoints.down("xl")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
  },
}));

const NavlinkCustom = styled(NavLink)(({ theme }) => ({
  boxSizing: "border-box",
  [`&.${classesx.navlinktab}`]: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "35px",
    padding: "20px 30px",
    maxWidth: "300px",
    color: "#7D8885",
    transition: "all 0.1s cubic-bezier(0.19, 1, 0.22, 1)  0s",
    [theme.breakpoints.down("xl")]: {
      padding: "16px 20px",
      maxWidth: "260px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "14px 18px",
      width: "100%",
      minWidth: "252px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "347px",
      padding: "12px 16px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
    "&:hover": {
      backgroundColor: "#298D74",
      "& .MuiTypography-root": {
        color: "#fff",
        fontWeight: 500,
      },
      "& .MuiListItemIcon-root svg": {
        fill: "#fff",
      },
    },
    "&.currpage": {
      backgroundColor: "#298D74",
      "& .MuiTypography-root": {
        color: "#fff",
        fontWeight: 500,
      },
      "& .MuiListItemIcon-root svg": {
        fill: "#fff",
      },
    },
  },
}));

const DrawerRoot = styled(Drawer)(({ theme }) => ({
  width: "unset",
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: "unset",
    boxSizing: "border-box",
    zIndex: 1304,
    borderRadius: "0px 35px 35px 0px",
    overflow: "hidden",
    boxShadow: "5px 0px 38px rgba(16, 53, 44, 0.08)",
    border: "unset",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "280px",
    },
    [theme.breakpoints.down("md")]: {
      left: "-600px",
      transition: "all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0s",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      borderRadius: "unset",
    },
  },

  [`& .MuiDrawer-paper::after`]: {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundImage: `url(${backgroundsidebar})`,
    width: "380px",
    height: "421px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    pointerEvents: "none",
    zIndex: -1,
  },
}));

export const Sidebar = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const theme = useTheme();
  const closesidebar = () => {
    const menu = document.querySelector(".sidebar-sidebar_wrapper");
    menu.classList.remove("open");
  };
  const navLinks = [
    {
      name: t("button.dashboard"),
      href: "/dashboard",
      Icon_component: <DashboardOutlinedIcon />,
    },
    {
      name: t("button.history"),
      href: "/report",
      Icon_component: <RestoreOutlinedIcon />,
    },
    {
      name: t("button.analytics"),
      href: "/analytics",
      Icon_component: <EqualizerOutlinedIcon />,
    },
    {
      name: t("button.pv_charging"),
      href: "/pv",
      Icon_component: <LightModeOutlinedIcon />,
    },
    {
      name: t("button.settings"),
      href: "/settings",
      Icon_component: <SettingsOutlinedIcon />,
    },
  ];

  if (
    location.pathname === "/signin" ||
    location.pathname === "/signup" ||
    location.pathname === "/" ||
    location.pathname === "/signout" ||
    location.pathname === "/__/reset_password" ||
    location.pathname === "/send_password_reset_email"
  ) {
    return null;
  } else {
    return (
      <>
        <RootDiv className={classesx.sidebar_wrapper}>
          <DrawerRoot variant="permanent">
            <RootDiv className={classesx.closebtn} onClick={closesidebar}>
              <IconButton color="success">
                <CloseOutlinedIcon sx={{ color: "#298D74" }} />
              </IconButton>
            </RootDiv>
            <RootDiv className={classesx.logo_wrapper}>
              <Link to="/dashboard">
                <img src={logo_sm} alt="Sidebar_top_lop" />
              </Link>
            </RootDiv>
            <RootDiv className={classesx.logo_wrapper_mobile_view}>
              <Link to="/dashboard" onClick={closesidebar}>
                <img src={carlogo} alt="Sidebar_top_bottom" />
              </Link>
            </RootDiv>
            <BoxRoot className={classesx.WrappperSidebar}>
              <List
                sx={{
                  padding: "unset",
                  position: "static",
                }}
              >
                {navLinks.map((text) => (
                  <ListItem key={text.name} disablePadding>
                    <ListItemButton
                      disableRipple={true}
                      sx={{
                        padding: "2px 16px",
                        [theme.breakpoints.down("xl")]: {
                          padding: " 2px 14px",
                        },
                        [theme.breakpoints.down("sm")]: {
                          justifyContent: "center",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                      }}
                    >
                      <NavlinkCustom
                        to={text.href}
                        className={classesx.navlinktab}
                        activeClassName="currpage"
                        onClick={closesidebar}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "unset",
                            maxWidth: "24px",
                            color: "unset",
                          }}
                        >
                          {text.Icon_component}
                        </ListItemIcon>
                        <ListItemTextRoot
                          primary={text.name}
                          className={classesx.navText}
                        />
                      </NavlinkCustom>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </BoxRoot>
          </DrawerRoot>
          <RootDiv
            className={classesx.html_shadow}
            onClick={closesidebar}
          ></RootDiv>
        </RootDiv>
      </>
    );
  }
};
