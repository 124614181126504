import React, { useEffect, useState } from "react";
import {
  Dialog,
  Select,
  CircularProgress,
  FormControlLabel,
  useTheme,
  List,
} from "@mui/material";
import { useSubscription, gql } from "@apollo/client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  addChargingStationToLB,
  deleteLoadBalancer,
  getLoadBalancers,
  removeChargingStationFromLB,
  updateLoadBalancer,
} from "../../store/actions/loadBalancer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomStatusCard from "../common/cards/CustomStatusCard";
import { getChargingStations } from "../../store/actions/chargingStation";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import { MUIBreadcrumbs } from "../common/Breadcrumbs/Breadcrumbs";
import { getFirebase } from "react-redux-firebase";
import {
  classesx as classesxReport,
  IconButtonRoot as IconButtonRootReport,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
} from "./Report";
import { RootDiv as RootDivReport } from "./Report";
import {
  DashboarClasses,
  DashboardDiv,
  GridRoot as GridRootDashboard,
} from "./Dashboard";
import {
  SettingClasses,
  SettingdDiv,
  RootH3 as RootH3title,
  ButtonRoot,
  TextFieldRoot,
  IconButtonRoot,
} from "./Settings";
import { IOSSwitch } from "./Dashboard";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingCardLoad } from "../common/loading_placeholder/Loading";
import Background from "../common/background/background";
export const PREFIX = "loadbalancer";
export const loadbalancerClasses = {
  GridRoot_container_accountsettings: `${PREFIX}-GridRoot_container`,
  section_1: `${PREFIX}-section_1`,
  section_2: `${PREFIX}-section_2`,
  loadbalancercard_cardbody: `${PREFIX}-loadbalancercard_cardbody`,
  cardheadblock: `${PREFIX}-cardheadblock`,
  cardheadh3: `${PREFIX}-cardheadh3`,
  cardContnetbottom: `${PREFIX}-cardContnetbottom`,
  lefttextblock: `${PREFIX}-lefttextblock`,
  maxpowerinput: `${PREFIX}-maxpowerinput`,
  switchblock: `${PREFIX}-switchblock`,
  cards_row_First: `${PREFIX}-cards_row_First`,
  stationid: `${PREFIX}-stationid`,
  statuslogo: `${PREFIX}-statuslogo`,
  bottomtext: `${PREFIX}-bottomtext`,
  bottomtext_right: `${PREFIX}-bottomtext_right`,
  bottomtext_left: `${PREFIX}-bottomtext_left`,
  charginstation_dialog: `${PREFIX}-charginstation_dialog`,
  section_3: `${PREFIX}-section_3`,
  loadbalancerlist: `${PREFIX}-loadbalancerlist`,
  ListRoot: `${PREFIX}-ListRoot`,
};
export const LoadbalancerDiv = styled("div")(({ theme }) => ({
  [`&.${loadbalancerClasses.section_1}`]: {
    boxSizing: "border-box",
  },
  [`&.${loadbalancerClasses.section_2} , &.${loadbalancerClasses.section_3} `]: {
    [`& .Noticetext`]: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: 1.19,
      color: "#1C1C1C",
      textTransform: "uppercase",
      paddingLeft: "30px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
      },
    },
  },
  [`&.${loadbalancerClasses.switchblock}`]: {
    minWidth: "250px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      marginBottom: "25px",
    },
  },
  [`&.${loadbalancerClasses.bottomtext}`]: {
    display: "flex",
    justifyContent: "space-between",
    [`&  h2`]: {
      fontWeight: 400,
      fontSize: "40px",
      lineHeight: 1.25,
      color: "#646464",
      textTransform: "capitalize",
      [theme.breakpoints.down("xl")]: {
        fontSize: "36px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "34px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    [`& p`]: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.5,
      color: "#B3B3B3",
      textTransform: "capitalize",
      [theme.breakpoints.down("xl")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        maxWidth: "100px",
      },
    },
  },
  [`&.${loadbalancerClasses.maxpowerinput}`]: {
    minWidth: "25.8%",
    [theme.breakpoints.down("xl")]: {
      minWidth: "50%",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
    },
  },
  [`&.${loadbalancerClasses.cardContnetbottom}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
    flexWrap: "wrap",
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "17px",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "14px",
    },
    [`& .switch_btn .MuiTypography-root `]: {
      marginLeft: "12px",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.7,
      color: "#263238",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
  },
  [`&.${loadbalancerClasses.lefttextblock}`]: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xl")]: {
      width: "50%",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [`& .powerval `]: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: 1.4,
      textTransform: "uppercase",
      color: "#263238",
      marginLeft: "20px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        width: "100%",
        marginLeft: "0px",
        marginTop: "2px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
  },
  [`&.${loadbalancerClasses.cardheadblock}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
}));

export const RootH3 = styled("h3")(({ theme }) => ({
  [`&.${loadbalancerClasses.cardheadh3}`]: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: 1.19,
    color: "#1C1C1C",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));
export const RootP = styled("p")(({ theme }) => ({
  [`&.${loadbalancerClasses.stationid}`]: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1.12,
    color: "#646464",
    margin: "10px 0 15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      margin: "10px 0 10px 0",
    },
  },
}));

export const ListRoot = styled(List)(({ theme }) => ({
  [`&.${loadbalancerClasses.ListRoot}`]: {
    padding: 0,
    [`& .MuiButtonBase-root`]: {
      marginBottom: "10px",
      width: "100%",
      padding: "20px 30px",
      borderRadius: "40px",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.15,
      textTransform: "capitalize",
      backgroundColor: "#2B8D75",
      color: "#fff",
      [theme.breakpoints.down("xl")]: {
        padding: "16px 25px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "14px 20px",
        fontSize: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px 16px",
        fontSize: "13px",
      },
    },
    [`&  .MuiButtonBase-root:last-child`]: {
      marginBottom: "0px!important",
    },
  },
}));

export const DialogRoot = styled(Dialog)(({ theme }) => ({
  [`&.${loadbalancerClasses.charginstation_dialog}`]: {
    zIndex: 1304,
    [`& .MuiBackdrop-root`]: {
      backgroundColor: "rgba(0, 0, 0, 0.842)",
    },
    [`& .MuiPaper-root.MuiPaper-elevation`]: {
      background: "#FFFFFF",
      boxShadow: "0px 1px 5px rgb(0 0 0 / 25%)",
      borderRadius: "35px",
      padding: "40px 45px",
      textAlign: "center",
      maxWidth: "485px",
      margin: "10px",
      [theme.breakpoints.down("lg")]: {
        padding: "30px 30px",
        borderRadius: "25px",
        maxWidth: "400px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
        borderRadius: "20px",
        maxWidth: "370px",
      },
      [`&::-webkit-scrollbar`]: {
        width: "10px",
        [theme.breakpoints.down("sm")]: {
          width: "5px",
        },
      },
      [`&::-webkit-scrollbar-track`]: {
        background: "  #2b8d7575",
        borderRadius: "10px",
      },
      [`&::-webkit-scrollbar-thumb `]: {
        background: "#237560",
        borderRadius: "10px",
      },
    },
    [`& .DialogRooth4 `]: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: 1.5,
      color: "#263238",
      textAlign: "center",
      textTransform: "uppercase",
      margin: "0 auto",

      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
        maxWidth: "95%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    [`& .btn_block `]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [`& .btn_block_wrapper `]: {
      margin: "0 10px",
      [theme.breakpoints.down("lg")]: {
        margin: "0 5px",
      },
    },
    [`& .notice_nochargingstation `]: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: 1.5,
      color: "#263238",
      textAlign: "center",
      textTransform: "capitalize",
      margin: "15px auto 18px auto",
    },
    [`& .DialogRootinput `]: {
      margin: "15px 0",
    },
  },
}));

function LoadBalancerDetail() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { uid } = useParams();
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const lbs = useSelector((state) => state.loadBalancer.loadBalancers);
  const chargingStations = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const [selectedStation, setSelectedStation] = useState(null);

  const [lbName, setLBName] = useState("");
  const [lbMaxPower, setLBMaxPower] = useState(0);
  const [lbPowerUtil, setLBPowerUtil] = useState(0.0);
  const [lbEnabled, setLBEnabled] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleMaxPowerOnChange(e) {
    setLBMaxPower(e.target.value);
  }

  function handleSwitchChange(e) {
    setLBEnabled(e.target.checked);
  }

  const handleChange = (event) => {
    setSelectedStation(event.target.value);
  };

  useEffect(() => {
    dispatch(getLoadBalancers());
  }, [uid]);

  const lb = lbs.find((l) => l.uid === uid);

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      chargingStations !== undefined &&
      chargingStations != null &&
      chargingStations.length > 0
    ) {
      setSelectedStation(chargingStations[0].charging_station_id);
    }
  }, [chargingStations]);

  useEffect(() => {
    if (lb !== undefined) {
      setLBName(lb.name);
      setLBMaxPower(lb.max_power);
      setLBEnabled(lb.enabled);
    }
  }, [lb]);

  const STATUS_SUBSCRIPTION = gql`
    subscription chargingStationFromLBSubscription($LBID: String!) {
      chargingStationsForLB(LBID: $LBID) {
        chargingStationID
        connected
        status
        activePowerImport
      }
    }
  `;

  const { data, loading } = useSubscription(STATUS_SUBSCRIPTION, {
    variables: { LBID: uid },
  });

  function getAliasFromStationID(chargingStationID) {
    if (
      chargingStations !== undefined &&
      chargingStations !== null &&
      chargingStations.length > 0
    ) {
      const chargingStationWithAlias = chargingStations.find(
        (cs) => cs.charging_station_id === chargingStationID
      );
      return chargingStationWithAlias ? chargingStationWithAlias.alias : "";
    }
  }

  useEffect(() => {
    if (!loading && data !== undefined && data.chargingStationsForLB !== null) {
      setLBPowerUtil(
        Number(
          data.chargingStationsForLB.reduce(
            (sum, { activePowerImport }) => sum + activePowerImport,
            0
          )
        ).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        })
      );
    }
  }, [data, loading]);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <MUIBreadcrumbs />
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${loadbalancerClasses.GridRoot_container_accountsettings}`}
          sx={{ marginBottom: "0px!important" }}
        >
          <LoadbalancerDiv className={loadbalancerClasses.section_1}>
            <SettingdDiv
              className={`${SettingClasses.CardBody} ${loadbalancerClasses.loadbalancercard_cardbody}`}
              sx={{
                minHeight: "175px!important",
                marginBottom: "30px",
              }}
            >
              <LoadbalancerDiv className={loadbalancerClasses.cardheadblock}>
                <RootH3 className={loadbalancerClasses.cardheadh3}>
                  {lbName}
                </RootH3>
                <IconButtonRoot
                  className={SettingClasses.IconButtonRoot}
                  onClick={() => {
                    dispatch(deleteLoadBalancer(uid));
                    history.replace("/vlb");
                  }}
                  sx={{
                    marginRight: "-8px",
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "0px",
                    },
                  }}
                  size="large"
                >
                  <DeleteOutlineIcon />
                </IconButtonRoot>
              </LoadbalancerDiv>
              <LoadbalancerDiv
                className={loadbalancerClasses.cardContnetbottom}
              >
                <LoadbalancerDiv className={loadbalancerClasses.lefttextblock}>
                  <RootH3title
                    className={`${SettingClasses.setting_card_h3}`}
                    sx={{
                      marginBottom: "0px!important",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "16px!important",
                      },
                      [theme.breakpoints.down("xs")]: {
                        fontSize: "14px!important",
                      },
                    }}
                  >
                    {t("text.charging_power")}
                  </RootH3title>
                  <p className="powerval"> {lbPowerUtil} kW</p>
                </LoadbalancerDiv>
                <LoadbalancerDiv className={loadbalancerClasses.maxpowerinput}>
                  <TextFieldRoot
                    disabled={false}
                    variant="outlined"
                    value={lbMaxPower}
                    title={t("text.max_charging_power_in_kw")}
                    label={t("text.max_charging_power_in_kw")}
                    onChange={handleMaxPowerOnChange}
                    className={SettingClasses.TextFieldRoot}
                    type="max_charging_power"
                    id="max_charging_power"
                    autoComplete="on"
                    sx={{ width: "100%" }}
                  />
                </LoadbalancerDiv>
                <LoadbalancerDiv
                  className={`${loadbalancerClasses.switchblock} switch_btn`}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    control={
                      <IOSSwitch
                        title={
                          lbEnabled
                            ? t("text.lb_active")
                            : t("text.lb_inactive")
                        }
                        color="primary"
                        checked={lbEnabled}
                        onChange={handleSwitchChange}
                      />
                    }
                    label={
                      lbEnabled ? t("text.lb_active") : t("text.lb_inactive")
                    }
                  />
                </LoadbalancerDiv>
                <ButtonRoot
                  variant="contained"
                  color="primary"
                  className={`${SettingClasses.ButtonRoot}`}
                  onClick={() => {
                    dispatch(
                      updateLoadBalancer(uid, lbName, lbMaxPower, lbEnabled)
                    );
                  }}
                  sx={{
                    marginRight: "-8px",
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "0px",
                    },
                  }}
                >
                  {t("button.save")}
                </ButtonRoot>
              </LoadbalancerDiv>
            </SettingdDiv>
          </LoadbalancerDiv>
          <LoadbalancerDiv className={loadbalancerClasses.section_2}>
            <DashboardDiv
              className={`${DashboarClasses.cards_row} ${loadbalancerClasses.cards_row_First}`}
            >
              {!loading && data !== undefined ? (
                data.chargingStationsForLB !== null ? (
                  getChargingStationsSortedByAlias(data.chargingStationsForLB, getAliasFromStationID).map((c) => {
                    return (
                      <>
                        <DashboardDiv
                          key={c.chargingStationID}
                          className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
                        >
                          <CustomStatusCard
                            chargingStationID={c.chargingStationID}
                            alias={c.alias || t("text.alias_not_found")}
                            connected={c.connected}
                            status={c.status}
                            activePowerImport={c.activePowerImport}
                            handleRemoveStationFromLB={() => {
                              dispatch(
                                removeChargingStationFromLB(
                                  uid,
                                  c.chargingStationID
                                )
                              );
                            }}
                          />
                        </DashboardDiv>
                      </>
                    );
                  })
                ) : (
                  <>
                    <p className="Noticetext">
                      {t("text.no_charging_station_added_yet")}
                    </p>
                  </>
                )
              ) : (
                <>
                  <DashboardDiv
                    className={`${DashboarClasses.Col_4} ${DashboarClasses.MB_30px}`}
                  >
                    <LoadingCardLoad />
                  </DashboardDiv>
                </>
              )}
            </DashboardDiv>
          </LoadbalancerDiv>
        </GridRootDashboard>
      </RootDivReport>
      <IconButtonRootReport
        aria-label="Add_popup"
        className={`${classesxReport.IconButtonRoot}`}
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: "16px",
          right: "16px",
        }}
        size="large"
      >
        <AddCircleOutlineRoundedIcon />
      </IconButtonRootReport>
      {chargingStations !== null ? (
        chargingStations.length === 0 ? (
          <DialogRoot
            open={open}
            onClose={handleClose}
            className={loadbalancerClasses.charginstation_dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <h4 className="DialogRooth4">{t("text.no_station_added_yet")}</h4>

            <p className="notice_nochargingstation">
              {t("text.no_station_added_notification")}
            </p>
            <ButtonRoot
              sx={{
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px!important",
                  padding: "10px 25px!important",
                },
                [theme.breakpoints.down("xs")]: {
                  padding: "8px 20px!important",
                },
              }}
              variant="contained"
              color="primary"
              className={`${SettingClasses.ButtonRoot}`}
              onClick={() => {
                history.push("/account-settings");
              }}
              autoFocus
            >
              {t("button.account_settings")}
            </ButtonRoot>
          </DialogRoot>
        ) : (
          <>
            <DialogRoot
              open={open}
              className={loadbalancerClasses.charginstation_dialog}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <h4 className="DialogRooth4">
                {`${t("text.add_charging_station_to")} ${lbName}`}
              </h4>
              <RootDivReport
                className={`${classesxReport.selectflex}`}
                sx={{
                  justifyContent: "center",
                  margin: "30px 0px 35px 0",
                  [theme.breakpoints.down("lg")]: {
                    margin: "20px 0px 25px 0",
                  },
                }}
              >
                {chargingStations !== null ? (
                  chargingStations.length === 0 ? (
                    t("text.no_station_added_notification")
                  ) : (
                    <>
                      <RootDivReport
                        className={classesxReport.selct_dropdown}
                        sx={{ minWidth: "290px" }}
                      >
                        <FormControlRootReport
                          className={classesxReport.FormControlRoot}
                        >
                          <Select
                            labelId="charging-station-select-outlined-label"
                            id="charging-station-select-outlined"
                            value={selectedStation}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            {chargingStations.map((cs) => (
                              <MenuItemRootReport
                                className={classesxReport.MenuItemRoot}
                                key={cs.unqid}
                                value={cs.charging_station_id}
                              >
                                {cs.alias || cs.charging_station_id}
                              </MenuItemRootReport>
                            ))}
                          </Select>
                        </FormControlRootReport>
                      </RootDivReport>
                    </>
                  )
                ) : (
                  <CircularProgress />
                )}
              </RootDivReport>

              <div className="btn_block">
                <div className="btn_block_wrapper">
                  <ButtonRoot
                    sx={{
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "16px!important",
                        padding: "10px 25px!important",
                      },
                      [theme.breakpoints.down("xs")]: {
                        padding: "8px 20px!important",
                      },
                    }}
                    variant="contained"
                    color="primary"
                    className={`${SettingClasses.ButtonRoot}`}
                    disabled={
                      chargingStations !== null && chargingStations.length === 0
                    }
                    onClick={handleClose}
                  >
                    {t("button.cancel")}
                  </ButtonRoot>
                </div>
                <div className="btn_block_wrapper">
                  <ButtonRoot
                    sx={{
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "16px!important",
                        padding: "10px 25px!important",
                      },
                      [theme.breakpoints.down("xs")]: {
                        padding: "8px 20px!important",
                      },
                    }}
                    variant="contained"
                    color="primary"
                    className={`${SettingClasses.ButtonRoot}`}
                    disabled={
                      chargingStations !== null && chargingStations.length === 0
                    }
                    onClick={() => {
                      dispatch(addChargingStationToLB(selectedStation, uid));
                      handleClose();
                    }}
                  >
                    {t("button.add")}
                  </ButtonRoot>
                </div>
              </div>
            </DialogRoot>
          </>
        )
      ) : null}
    </>
  );
}

function getChargingStationsSortedByAlias(chargingStations, getAliasFromStationID) {
  const mappedStationsWithAlias = chargingStations.map((c) => {
    c.alias = getAliasFromStationID(c.chargingStationID)
    return c
  })

  const sortedStationsByAlias = mappedStationsWithAlias.sort((a, b) => {
    const aliasA = a.alias.toLowerCase()
    const aliasB = b.alias.toLowerCase()

    if (aliasA < aliasB) {
      return -1;
    }
    if (aliasA > aliasB) {
      return 1;
    }
  
    // names must be equal
    return 0;
  })
  return sortedStationsByAlias
}

export default LoadBalancerDetail;
