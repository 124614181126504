import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  MenuItem,
  Select,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getChargingStations } from "../../store/actions/chargingStation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getChargingStation,
  getMeterPowerfoxRQ,
  updatePVMode,
  insertOrUpdateMeterPowerfox,
  updatePhases,
  deletePowerfox,
} from "../../lib/api";
import { styled } from "@mui/material/styles";
import { classesx as classesxReport } from "./Report";
import {
  RootDiv as RootDivReport,
  Labelh4 as Labelh4Report,
  FormControlRoot as FormControlRootReport,
  MenuItemRoot as MenuItemRootReport,
  IconButtonRoot as IconButtonRootReport,
} from "./Report";
import {
  DashboarClasses,
  DashboardDiv,
  GridRoot as GridRootDashboard,
} from "./Dashboard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  SettingClasses,
  SettingdDiv,
  RootH3,
  TextFieldRoot,
  PrettoSlider,
  IconButtonRoot,
} from "./Settings";
import {
  Inputstack,
  Selectstack,
  Groupbtnstack,
  SliderSkeleton,
} from "../common/loading_placeholder/Loading";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

import Background from "../common/background/background";
import { getFirebase } from "react-redux-firebase";

export const PREFIX = "PVSettings";
export const PVSettingsClasses = {
  GridRoot_container_PVSettings: `${PREFIX}-GridRoot_container`,
  PVsurplus_card: `${PREFIX}-PVsurplus_card`,
  PVSettings_cardbody: `${PREFIX}-PVSettings_cardbody`,
  cards_row_First: `${PREFIX}-cards_row_First`,
  pvsetting_card_subheader: `${PREFIX}-pvsetting_card_subheader`,
  ButtonRoot: `${PREFIX}-ButtonRoot`,
  PVsurplus_card_body: `${PREFIX}-PVsurplus_card_body`,
  loginData_block: `${PREFIX}-loginData_block`,
  pvsetting_card_subheader_dark: `${PREFIX}-pvsetting_card_subheader_dark`,
  Group_btn: `${PREFIX}-Group_btn`,
};

export const PVSettingsdDiv = styled("div")(({ theme }) => ({
  [`&.${PVSettingsClasses.PVsurplus_card}`]: {
    [`& .${PVSettingsClasses.PVSettings_cardbody}`]: {
      minHeight: "415px",
      padding: "30px 35px",
      [theme.breakpoints.down("lg")]: {
        padding: "25px 25px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "15px 15px",
      },
    },
  },
  [`&.${PVSettingsClasses.loginData_block}`]: {
    background: "#f4f9f8",
    borderRadius: "10px",
    padding: "30px 30px 30px 30px",
    display: "flex",
    width: "100%",
    maxWidth: "90%",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    justifyContent: " space-between",
    minHeight: "160px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      padding: "25px 25px 25px 25px",
      minHeight: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 15px 15px",
      minHeight: "115px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px 10px 10px",
      minHeight: "100px",
    },
  },
}));

export const RootP = styled("p")(({ theme }) => ({
  [`&.${PVSettingsClasses.pvsetting_card_subheader}`]: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: 1.3,
    color: "#646464",
    marginTop: "20px",
    marginBottom: "15px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      [`& .MuiSvgIcon-root`]: {
        fontSize: "1.3rem",
      },
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  [`&.${PVSettingsClasses.pvsetting_card_subheader_dark}`]: {
    fontSize: "14px",
    lineHeight: 1.3,
    color: "#646464",
    fontWeight: 600,
    marginBottom: "40px",
    marginTop: "10px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      [`& .MuiSvgIcon-root`]: {
        fontSize: "1.3rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
}));

export const MenuItemRoot = styled(MenuItem)(({ theme }) => ({
  [`&.${DashboarClasses.MenuItemRoot}`]: {
    lineHeight: "normal",
    fontWeight: 500,
    padding: "10px 5px 10px 10px",
    minHeight: "unset",
    [theme.breakpoints.down("xl")]: {
      padding: "8px 5px 8px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px 8px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
}));

export const ButtonRoot = styled(LoadingButton)(({ theme }) => ({
  [`&.${PVSettingsClasses.ButtonRoot}`]: {
    padding: "10px 27.5px",
    fontWeight: 400,
    fontSize: "20px",
    borderRadius: "35px",
    textTransform: "none",
    lineHeight: "normal",
    boxShadow: "unset",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      padding: "8px 20px",
    },
  },
}));

export const ToggleButtonGroupRoot = styled(ToggleButtonGroup)(({ theme }) => ({
  [`&.${PVSettingsClasses.Group_btn}`]: {
    overflow: "hidden",
    borderRadius: "10px",
    border: "2px solid #298D74",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px",
    },
    [`& .MuiButtonBase-root`]: {
      borderRadius: "unset",
      border: "unset",
      color: "#298D74",
      fontSize: "14px",
      fontWeight: 600,
      padding: "12px",
      backgroundColor: "#298d7433",
      [theme.breakpoints.down("md")]: {
        padding: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        padding: "8px",
      },
    },
    [`& .MuiButtonBase-root.Mui-selected`]: {
      color: "#fff",
      backgroundColor: "#298D74",
    },
  },
}));

function PVSettings() {
  const dispatch = useDispatch();
  const currentUser = getFirebase().auth().currentUser;
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [selectedStation, setSelectedStation] = useState("");

  const meterPowerfox = useQuery(["powerfox"], getMeterPowerfoxRQ, {
    retry: 1,
    retryDelay: 1
  });
  const changePVMode = useMutation(["powerfox"], updatePVMode);
  const insertOrUpdateMeterPowerfoxMutation = useMutation(
    ["powerfox"],
    insertOrUpdateMeterPowerfox
  );
  const updatePhasesMutation = useMutation(
    ["cs", selectedStation],
    updatePhases
  );

  const deletePowerfoxCredentials = useMutation(deletePowerfox);

  const chargingStationDetails = useQuery(
    ["cs", selectedStation],
    () => getChargingStation(selectedStation),
    { enabled: selectedStation !== "", refetchIntervalInBackground: true }
  );

  const chargingStations = useSelector(
    (state) => state.chargingStation.chargingStations
  );

  const [minPwrSliderLabel, setMinPwrSliderLabel] = useState(4.13);
  const [uid, setUID] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phases, setPhases] = useState(3);
  const [pvMode, setPVMode] = useState("OFF");
  const [batteryCheckMode, setBatteryCheckMode] = useState(0);
  const [minPower, setMinPower] = useState(4.13);
  const [maxHomeConsumption, setMaxHomeConsumption] = useState(7);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [minExcessPowerMarks, setMinExcessPowerMarks] = useState([
    {
      value: pvMode === "PV_SUPPORTED" ? 0.1 : minPwrSliderLabel,
      label: `${pvMode === "PV_SUPPORTED" ? 0.1 : minPwrSliderLabel} kW`,
    },
    {
      value: 10.0,
      label: "10.0 kW",
    },
  ]);

  const homeConsumptionMarks = [
    {
      value: 0.1,
      label: `0.1 kW`,
    },
    {
      value: 10.0,
      label: "10.0 kW",
    },
  ];

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(getChargingStations());
    }
  }, [currentUser]);

  useEffect(() => {
    setMinExcessPowerMarks([
      {
        value: pvMode === "PV_SUPPORTED" ? 0.1 : minPwrSliderLabel,
        label: `${pvMode === "PV_SUPPORTED" ? 0.1 : minPwrSliderLabel} kW`,
      },
      {
        value: 10.0,
        label: "10.0 kW",
      },
    ]);
  }, [pvMode, minPwrSliderLabel]);

  useEffect(() => {
    if (!chargingStationDetails.isLoading) {
      if (pvMode === "PV_ONLY") {
        switch (chargingStationDetails.data.phases) {
          case 1:
            setMinPwrSliderLabel(1.38);
            break;
          case 2:
            setMinPwrSliderLabel(2.76);
            break;
          case 3:
            setMinPwrSliderLabel(4.13);
            break;
          default:
            setMinPwrSliderLabel(4.13);
        }
      } else if (pvMode === "PV_SUPPORTED") {
        setMinPwrSliderLabel(0.1);
      }
    }
  }, [chargingStationDetails.isLoading, selectedStation, pvMode]);

  useEffect(() => {
    if (
      chargingStations != null &&
      !meterPowerfox.isLoading &&
      meterPowerfox.isSuccess &&
      chargingStations.length > 0 &&
      chargingStations.some((cs) => {
        if (cs.charging_station_id === meterPowerfox.data.charging_station_id)
          return true;
        return false;
      })
    ) {
      setSelectedStation(meterPowerfox.data.charging_station_id);
    } else if (chargingStations != null && chargingStations.length > 0) {
      setSelectedStation(chargingStations[0].charging_station_id);
    }
  }, [chargingStations, meterPowerfox.isLoading]);

  useEffect(() => {
    if (
      !meterPowerfox.isLoading &&
      meterPowerfox.isSuccess &&
      meterPowerfox.data !== ""
    ) {
      setUID(meterPowerfox.data.uid);
      setUsername(meterPowerfox.data.username);
      setPassword(meterPowerfox.data.password);
      setPVMode(meterPowerfox.data.pv_mode);
      setMaxHomeConsumption(
        meterPowerfox.data.pv_supported_max_home_consumption
      );
      setBatteryCheckMode(meterPowerfox.data.battery_check_duration_mins);
      setMinPower(meterPowerfox.data.min_excess_power);
    }
  }, [meterPowerfox.isLoading]);

  useEffect(() => {
    if (!chargingStationDetails.isLoading && !chargingStationDetails.isError) {
      setPhases(chargingStationDetails.data.phases);
    }
  }, [chargingStationDetails.isLoading]);

  async function handleStationChange(event) {
    const newValue = event.target.value;
    setSelectedStation(newValue);

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        charging_station_id: newValue,
        _edited: true,
      };
      return newData;
    });
  }

  async function handleUsernameChange(event) {
    const newValue = event.target.value;
    setUsername(newValue);

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        username: newValue,
        _edited: true,
      };
      return newData;
    });
  }

  async function handlePasswordChange(event) {
    const newValue = event.target.value;
    setPassword(newValue);

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        password: newValue,
        _edited: true,
      };
      return newData;
    });
  }

  const handlePasswordVisibleIcon = () => {
    setPasswordVisible(!passwordVisible);
  };

  async function handleMinExcessPowerSlider(event, newValue) {
    setMinPower(newValue);

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        min_excess_power: newValue,
        _edited: true,
      };
      return newData;
    });
  }

  async function handleReduceMinPower() {
    var newMinPwrVal;

    setMinPower((prevMinPower) => {
      newMinPwrVal = Number((Number(prevMinPower) - 0.1).toFixed(2));
      newMinPwrVal =
        newMinPwrVal < minPwrSliderLabel ? minPwrSliderLabel : newMinPwrVal;

      return newMinPwrVal;
    });

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        min_excess_power: newMinPwrVal,
        _edited: true,
      };
      return newData;
    });
  }

  async function handleIncreaseMinPower() {
    var newMinPwrVal;

    setMinPower((prevMinPower) => {
      newMinPwrVal = Number((Number(prevMinPower) + 0.1).toFixed(2));
      newMinPwrVal = newMinPwrVal > 10.0 ? 10.0 : newMinPwrVal;
      return newMinPwrVal;
    });

    await queryClient.cancelQueries({
      queryKey: ["powerfox"],
    });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        min_excess_power: newMinPwrVal,
        _edited: true,
      };
      return newData;
    });
  }

  async function handleReduceMaxPowerConsumption() {
    var newMaxPowerConsumption;

    setMaxHomeConsumption((prevMaxPower) => {
      newMaxPowerConsumption = Number((Number(prevMaxPower) - 0.1).toFixed(2));
      newMaxPowerConsumption =
        newMaxPowerConsumption < 0.1 ? 0.1 : newMaxPowerConsumption;
      return newMaxPowerConsumption;
    });

    await queryClient.cancelQueries({ queryKey: ["powerfox"] });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        pv_supported_max_home_consumption: newMaxPowerConsumption,
        _edited: true,
      };

      return newData;
    });
  }

  async function handleIncreaseMaxPowerConsumption() {
    var newMaxPowerConsumption;

    setMaxHomeConsumption((prevMaxPower) => {
      newMaxPowerConsumption = Number((Number(prevMaxPower) + 0.1).toFixed(2));
      newMaxPowerConsumption =
        newMaxPowerConsumption > 10.0 ? 10.0 : newMaxPowerConsumption;
      return newMaxPowerConsumption;
    });

    await queryClient.cancelQueries({ queryKey: ["powerfox"] });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        pv_supported_max_home_consumption: newMaxPowerConsumption,
        _edited: true,
      };

      return newData;
    });
  }

  async function handleMaxPowerConsumptionSlider(event, newValue) {
    setMaxHomeConsumption(newValue);

    await queryClient.cancelQueries({ queryKey: ["powerfox"] });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        pv_supported_max_home_consumption: newValue,
        _edited: true,
      };

      return newData;
    });
  }

  const handlePVModeToggleChange = async (event, newPVMode) => {
    setPVMode(newPVMode);

    await queryClient.cancelQueries({ queryKey: ["powerfox"] });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        pv_mode: newPVMode,
        _edited: true,
      };

      return newData;
    });
  };

  const handleBatteryModeToggleChange = async (event, newBatteryMode) => {
    setBatteryCheckMode(newBatteryMode);

    await queryClient.cancelQueries({ queryKey: ["powerfox"] });

    queryClient.setQueryData(["powerfox"], (old) => {
      const newData = {
        ...old,
        battery_check_duration_mins: newBatteryMode,
        _edited: true,
      };

      return newData;
    });
  };

  const getMinPowerForPhase = (phases) => {
    switch (phases) {
      case 1:
        return 1.38;
      case 2:
        return 2.76;
      case 3:
        return 4.13;
      default:
        return 4.13;
    }
  };

  const handlePhaseChange = async (event) => {
    const newPhaseVal = event.target.value;
    setPhases(newPhaseVal);
    if (pvMode == "PV_ONLY") {
      const minPwrSliderLabelVal = getMinPowerForPhase(newPhaseVal);
      setMinPwrSliderLabel(minPwrSliderLabelVal);

      // use a local variable because updating minPower with setMinPower
      // does not immediately update the variable. Using minPower after calling setMinPower
      // does not guarantee the variable has already been changed. Such changes over setState should
      // be watched over a useEffect.
      // Since that would be unnecessary for our use case here, using a local variable to keep track of the
      // final minPower which should be use in the request payload be suffice for our use-case.
      var minPowerReqVar = minPower;

      if (minPower < minPwrSliderLabelVal) {
        setMinPower(minPwrSliderLabelVal);
        minPowerReqVar = minPwrSliderLabelVal;
      }

      await queryClient.cancelQueries({
        queryKey: ["powerfox"],
      });

      queryClient.setQueryData(["powerfox"], (old) => {
        const newData = {
          ...old,
          min_excess_power: minPowerReqVar,
          _edited: true,
        };
        return newData;
      });
    }
    await queryClient.cancelQueries({
      queryKey: ["cs", selectedStation],
    });

    queryClient.setQueryData(["cs", selectedStation], (old) => {
      const newData = {
        ...old,
        phases: newPhaseVal,
      };
      return newData;
    });
  };

  const handleDialogClose = (event) => {
    setDialogOpen(false);
  };

  const pvModeToggleControl = {
    value: pvMode,
    onChange: handlePVModeToggleChange,
    exclusive: true,
  };

  const batteryModeToggleControl = {
    value: batteryCheckMode,
    onChange: handleBatteryModeToggleChange,
    exclusive: true,
  };

  const pvSettingsToast = (statusCode) => {
    if (statusCode == 200) {
      return toast.success(t("text.pv_settings_saved"));
    } else if (statusCode == 401) {
      return toast.error(t("error.wrong_powerfox_credentials"));
    } else if (statusCode == 400) {
      return toast.error(t("error.malformed_request"));
    } else if (statusCode == 429) {
      return toast.error(t("error.too_many_req_powerfox_api"));
    } else if (statusCode == 500) {
      return toast.error(t("error.internal_server_error"));
    } else {
      return toast.error(t("error.unknown_error") + statusCode);
    }
  };

  useEffect(() => {
    if (insertOrUpdateMeterPowerfoxMutation.isSuccess) {
      pvSettingsToast(insertOrUpdateMeterPowerfoxMutation.data.status);
    } else if (insertOrUpdateMeterPowerfoxMutation.isError) {
      pvSettingsToast(
        insertOrUpdateMeterPowerfoxMutation.error.response.status
      );
    }
  }, [insertOrUpdateMeterPowerfoxMutation.isLoading]);

  return (
    <>
      <Background />
      <RootDivReport className={classesxReport.page_wrapper}>
        <GridRootDashboard
          item
          xs={12}
          className={`${DashboarClasses.GridRoot_container} ${PVSettingsClasses.GridRoot_container_PVSettings}`}
        >
          <RootDivReport
            className={classesxReport.form_row}
            sx={{
              justifyContent: "space-between",
              marginBottom: "30px",
              [theme.breakpoints.down("lg")]: {
                alignItems: "self-end!important",
              },
              [theme.breakpoints.down("xs")]: {
                marginBottom: "20px",
              },
            }}
          ></RootDivReport>
          <PVSettingsdDiv className={PVSettingsClasses.PVsurplus_card}>
            <SettingdDiv
              className={`${SettingClasses.CardBody} ${PVSettingsClasses.PVSettings_cardbody}`}
            >
              <SettingdDiv className={`${SettingClasses.CardTop_content}`}>
                <RootH3
                  className={`${SettingClasses.setting_card_h3}`}
                  sx={{ marginBottom: "20px" }}
                >
                  {t("text.PV_surplus_charging")}
                </RootH3>
                <DashboardDiv
                  sx={{ alignItems: "unset!important" }}
                  className={`${DashboarClasses.cards_row} ${PVSettingsClasses.cards_row_First}`}
                >
                  <DashboardDiv
                    className={`${DashboarClasses.Col_12} ${DashboarClasses.MB_30px}`}
                  >
                    <PVSettingsdDiv
                      className={PVSettingsClasses.PVsurplus_card_body}
                    >
                      <RootP
                        className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                      >
                        {t("text.Powerfox_Login_Data")}
                      </RootP>
                      <form action="">
                        <PVSettingsdDiv
                          className={PVSettingsClasses.loginData_block}
                        >
                          {meterPowerfox.isLoading ? (
                            <Inputstack />
                          ) : (
                            <>
                              <TextFieldRoot
                                value={username}
                                variant="outlined"
                                label={t("text.email")}
                                onChange={handleUsernameChange}
                                className={SettingClasses.TextFieldRoot}
                                sx={{
                                  margin: "0px 0px 15px 0px!important",
                                  width: "100%",
                                }}
                              />
                              <TextFieldRoot
                                value={password}
                                variant="outlined"
                                placeholder="*******"
                                type={passwordVisible ? "text" : "password"}
                                label={t("text.password")}
                                className={SettingClasses.TextFieldRoot}
                                sx={{ width: "100%" }}
                                onChange={handlePasswordChange}
                                autoComplete="current-password"
                              />
                              <IconButtonRoot
                                className={SettingClasses.IconButtonRoot}
                                onClick={handlePasswordVisibleIcon}
                                sx={{
                                  position: "absolute",
                                  right: "30px",
                                  bottom: "30px",
                                  [theme.breakpoints.down("lg")]: {
                                    right: "25px",
                                    bottom: "25px",
                                  },
                                  [theme.breakpoints.down("sm")]: {
                                    right: "15px",
                                    bottom: "16px",
                                  },
                                  [theme.breakpoints.down("xs")]: {
                                    right: "10px",
                                    bottom: "11px",
                                  },
                                }}
                              >
                                {passwordVisible ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButtonRoot>
                            </>
                          )}
                        </PVSettingsdDiv>
                        <Fragment>
                          <RootP
                            className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                          >
                            {t("text.delete_powerfox_credentials")}
                            <Tooltip
                              sx={{ marginLeft: "8px" }}
                              title={t("text.delete_powerfox_credentials_info")}
                            >
                              <InfoOutlined />
                            </Tooltip>
                          </RootP>
                          <LoadingButton
                            variant="contained"
                            color="error"
                            onClick={() => setDialogOpen(true)}
                          >
                            {t("button.delete")}
                          </LoadingButton>
                        </Fragment>
                      </form>
                    </PVSettingsdDiv>
                  </DashboardDiv>
                  <DashboardDiv
                    className={`${DashboarClasses.Col_12} ${DashboarClasses.MB_30px}`}
                  >
                    <PVSettingsdDiv
                      className={PVSettingsClasses.PVsurplus_card_body}
                    >
                      <RootP
                        className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                      >
                        {t("text.pair_cs_with_pv")}
                      </RootP>
                      {chargingStations !== null ? (
                        chargingStations.length === 0 ? (
                          t("text.no_station_added_notification")
                        ) : (
                          <>
                            <RootDivReport
                              className={classesxReport.form_row}
                              sx={{
                                marginBottom: "30px",
                                [theme.breakpoints.down("sm")]: {
                                  marginBottom: "25px",
                                },
                                [theme.breakpoints.down("xs")]: {
                                  marginBottom: "20px",
                                },
                              }}
                            >
                              <DashboardDiv
                                className={DashboarClasses.Input_Wrapper}
                                sx={{
                                  [theme.breakpoints.down("sm")]: {
                                    width: "100%!important",
                                  },
                                }}
                              >
                                <RootDivReport
                                  className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
                                  sx={{
                                    [theme.breakpoints.down("lg")]: {
                                      flexDirection: "column",
                                      alignItems: "flex-start!important",
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                      marginRight: "0px!important",
                                    },
                                  }}
                                >
                                  <RootDivReport
                                    className={classesxReport.selct_dropdown}
                                  >
                                    <FormControlRootReport
                                      className={classesxReport.FormControlRoot}
                                    >
                                      <Select
                                        labelId="charging-station-select-outlined-label"
                                        id="charging-station-select-outlined"
                                        value={
                                          selectedStation ? selectedStation : ""
                                        }
                                        onChange={handleStationChange}
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                      >
                                        {chargingStations.map((cs) => (
                                          <MenuItemRootReport
                                            className={
                                              classesxReport.MenuItemRoot
                                            }
                                            key={cs.unqid}
                                            value={cs.charging_station_id}
                                          >
                                            {cs.alias || cs.charging_station_id}
                                          </MenuItemRootReport>
                                        ))}
                                      </Select>
                                    </FormControlRootReport>
                                  </RootDivReport>
                                </RootDivReport>
                              </DashboardDiv>
                            </RootDivReport>
                          </>
                        )
                      ) : (
                        <Selectstack />
                      )}
                      <RootP
                        className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                      >
                        {t("text.phases_title")}
                        <Tooltip
                          sx={{ marginLeft: "8px" }}
                          title={t("text.phases_info")}
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </RootP>
                      {chargingStationDetails.isLoading ? (
                        <Selectstack />
                      ) : (
                        <>
                          {" "}
                          <RootDivReport
                            className={classesxReport.form_row}
                            sx={{
                              marginBottom: "30px",
                              [theme.breakpoints.down("sm")]: {
                                marginBottom: "25px",
                              },
                              [theme.breakpoints.down("xs")]: {
                                marginBottom: "20px",
                              },
                            }}
                          >
                            <DashboardDiv
                              className={DashboarClasses.Input_Wrapper}
                              sx={{
                                [theme.breakpoints.down("sm")]: {
                                  width: "100%!important",
                                },
                              }}
                            >
                              <RootDivReport
                                className={`${classesxReport.selectflex} ${classesxReport.MR_20px}`}
                                sx={{
                                  [theme.breakpoints.down("lg")]: {
                                    flexDirection: "column",
                                    alignItems: "flex-start!important",
                                  },
                                  [theme.breakpoints.down("sm")]: {
                                    marginRight: "0px!important",
                                  },
                                }}
                              >
                                <RootDivReport
                                  className={classesxReport.selct_dropdown}
                                >
                                  <FormControlRootReport
                                    className={classesxReport.FormControlRoot}
                                  >
                                    <Select
                                      labelId="charging-station-select-outlined-label"
                                      id="charging-station-select-outlined"
                                      value={phases}
                                      onChange={handlePhaseChange}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItemRootReport
                                        key={1}
                                        value={1}
                                        className={classesxReport.MenuItemRoot}
                                      >
                                        1 {t("text.phases")}
                                      </MenuItemRootReport>
                                      <MenuItemRootReport
                                        key={2}
                                        value={2}
                                        className={classesxReport.MenuItemRoot}
                                      >
                                        2 {t("text.phases")}
                                      </MenuItemRootReport>
                                      <MenuItemRootReport
                                        key={3}
                                        value={3}
                                        className={classesxReport.MenuItemRoot}
                                      >
                                        3 {t("text.phases")}
                                      </MenuItemRootReport>
                                    </Select>
                                  </FormControlRootReport>
                                </RootDivReport>
                              </RootDivReport>
                            </DashboardDiv>
                          </RootDivReport>
                        </>
                      )}
                      <RootP
                        className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                      >
                        {t("text.pv_mode_title")}
                        <Tooltip
                          sx={{ marginLeft: "8px" }}
                          title={t("text.toggle_info")}
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </RootP>
                      {meterPowerfox.isLoading ? (
                        <Groupbtnstack />
                      ) : (
                        <>
                          <ToggleButtonGroupRoot
                            {...pvModeToggleControl}
                            exclusive={true}
                            className={PVSettingsClasses.Group_btn}
                            sx={{ marginBottom: "15px" }}
                          >
                            <ToggleButton
                              value="OFF"
                              sx={{ textTransform: "none" }}
                            >
                              {t("button.off")}
                            </ToggleButton>
                            <ToggleButton
                              sx={{
                                borderLeft: "2px solid #298D74!important",
                                borderRight: "2px solid #298D74!important",
                                textTransform: "none",
                              }}
                              value="PV_ONLY"
                            >
                              {t("button.pv")}
                            </ToggleButton>
                            <ToggleButton
                              value="PV_SUPPORTED"
                              sx={{ textTransform: "none" }}
                            >
                              {t("button.pv_supported")}
                            </ToggleButton>
                          </ToggleButtonGroupRoot>
                        </>
                      )}
                      {pvMode !== "OFF" ? (
                        <>
                          {/* <RootP
                            className={`${PVSettingsClasses.pvsetting_card_subheader_dark}`}
                          >
                            {t("text.min_excess_power")}
                            <Tooltip
                              sx={{ marginLeft: "8px" }}
                              title={t("text.min_excess_pwr_info")}
                            >
                              <InfoOutlined />
                            </Tooltip>
                          </RootP>
                          {meterPowerfox.isLoading ||
                          chargingStationDetails.isLoading ? (
                            <SliderSkeleton />
                          ) : (
                            <>
                              <SettingdDiv
                                className={`${SettingClasses.Slider_block}`}
                                sx={{
                                  marginTop: "0px!important",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <IconButton
                                  sx={{ marginRight: "10px" }}
                                  onClick={handleReduceMinPower}
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                                <PrettoSlider
                                  value={minPower}
                                  defaultValue={4.13}
                                  step={0.1}
                                  marks={minExcessPowerMarks}
                                  min={minPwrSliderLabel}
                                  max={10}
                                  onChange={handleMinExcessPowerSlider}
                                  aria-label="pretto slider"
                                  aria-labelledby="current-slider"
                                  valueLabelDisplay="on"
                                />
                                <IconButton
                                  sx={{ marginLeft: "10px" }}
                                  onClick={handleIncreaseMinPower}
                                >
                                  <AddCircleOutline />
                                </IconButton>
                              </SettingdDiv>
                            </>
                          )} */}
                          {pvMode === "PV_SUPPORTED" ? (
                            <>
                              <RootP
                                className={`${PVSettingsClasses.pvsetting_card_subheader_dark}`}
                              >
                                {t("text.max_home_consumption")}
                                <Tooltip
                                  sx={{ marginLeft: "8px" }}
                                  title={t("text.max_home_consumption_info")}
                                >
                                  <InfoOutlined />
                                </Tooltip>
                              </RootP>
                              {meterPowerfox.isLoading ||
                              chargingStationDetails.isLoading ? (
                                <SliderSkeleton />
                              ) : (
                                <>
                                  <SettingdDiv
                                    className={`${SettingClasses.Slider_block}`}
                                    sx={{
                                      marginTop: "0px!important",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <IconButton
                                      sx={{ marginRight: "10px" }}
                                      onClick={handleReduceMaxPowerConsumption}
                                    >
                                      <RemoveCircleOutline />
                                    </IconButton>
                                    <PrettoSlider
                                      value={
                                        maxHomeConsumption
                                          ? maxHomeConsumption
                                          : 7.0
                                      }
                                      defaultValue={7.0}
                                      step={0.1}
                                      marks={homeConsumptionMarks}
                                      min={0.1}
                                      max={10}
                                      onChange={handleMaxPowerConsumptionSlider}
                                      aria-labelledby="current-slider"
                                      valueLabelDisplay="on"
                                      aria-label="pretto slider"
                                    />
                                    <IconButton
                                      sx={{ marginLeft: "10px" }}
                                      onClick={
                                        handleIncreaseMaxPowerConsumption
                                      }
                                    >
                                      <AddCircleOutline />
                                    </IconButton>
                                  </SettingdDiv>
                                </>
                              )}
                            </>
                          ) : null}
                          <RootP
                            className={`${PVSettingsClasses.pvsetting_card_subheader}`}
                          >
                            {t("text.battery_check")}
                            <Tooltip
                              sx={{ marginLeft: "8px" }}
                              title={t("text.battery_check_info")}
                            >
                              <InfoOutlined />
                            </Tooltip>
                          </RootP>
                          {meterPowerfox.isLoading ? (
                            <Groupbtnstack />
                          ) : (
                            <>
                              <ToggleButtonGroupRoot
                                {...batteryModeToggleControl}
                                exclusive={true}
                                className={PVSettingsClasses.Group_btn}
                                sx={{ marginBottom: "15px" }}
                              >
                                <ToggleButton
                                  value={0}
                                  defaultChecked
                                  sx={{ textTransform: "none" }}
                                >
                                  {t("button.off")}
                                </ToggleButton>
                                <ToggleButton
                                  value={30}
                                  sx={{
                                    borderLeft: "2px solid #298D74!important",
                                    textTransform: "none",
                                  }}
                                >
                                  {t("30mins")}
                                </ToggleButton>
                                <ToggleButton
                                  value={60}
                                  sx={{
                                    borderLeft: "2px solid #298D74!important",
                                    textTransform: "none",
                                  }}
                                >
                                  {t("60mins")}
                                </ToggleButton>
                                <ToggleButton
                                  value={120}
                                  sx={{
                                    borderLeft: "2px solid #298D74!important",
                                    textTransform: "none",
                                  }}
                                >
                                  {t("120mins")}
                                </ToggleButton>
                              </ToggleButtonGroupRoot>
                            </>
                          )}
                        </>
                      ) : null}
                    </PVSettingsdDiv>
                  </DashboardDiv>
                </DashboardDiv>
              </SettingdDiv>
              <SettingdDiv className={`${SettingClasses.card_btn_block}`}>
                <ButtonRoot
                  className={`${PVSettingsClasses.ButtonRoot}`}
                  variant="contained"
                  color="primary"
                  loading={
                    insertOrUpdateMeterPowerfoxMutation.isLoading ||
                    changePVMode.isLoading ||
                    updatePhasesMutation.isLoading
                  }
                  disabled={false}
                  onClick={() => {
                    const powerfox = queryClient.getQueryData(["powerfox"]);

                    if (meterPowerfox.isFetched && powerfox) {
                      insertOrUpdateMeterPowerfoxMutation.mutate({
                        uid: powerfox.uid,
                        chargingStationID:
                          powerfox.charging_station_id || selectedStation,
                        mode: powerfox.pv_mode || pvMode,
                        minExcessPower: powerfox.min_excess_power || minPower,
                        pvSupportedMaxHomeConsumption:
                          powerfox.pv_supported_max_home_consumption ||
                          maxHomeConsumption,
                        batteryCheckDurationMins:
                          powerfox.battery_check_duration_mins ||
                          batteryCheckMode,
                        username: powerfox.username || username,
                        password: powerfox.password || password,
                      });
                    }

                    updatePhasesMutation.mutate({
                      chargingStationID: selectedStation,
                      phases: phases,
                    });
                  }}
                >
                  {t("button.save")}
                </ButtonRoot>
              </SettingdDiv>
            </SettingdDiv>
          </PVSettingsdDiv>
        </GridRootDashboard>
      </RootDivReport>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("text.are_you_sure")}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("button.no")}</Button>
          <LoadingButton
            variant="contained"
            loading={deletePowerfoxCredentials.isLoading}
            color="error"
            onClick={() => {
              deletePowerfoxCredentials.mutate();
              setDialogOpen(false);
              setUsername("");
              setPassword("");
            }}
            autoFocus
          >
            {t("button.yes_delete")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Toaster />
    </>
  );
}

export default PVSettings;
