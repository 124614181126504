import * as React from "react";
import { styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useHistory } from "react-router-dom";

export const PREFIX = "MUIBreadcrumbs";
export const MUIBreadcrumbsclasses = {
  MUIBreadcrumbs: `${PREFIX}-Root`,
  ChipRoot: `${PREFIX}-ChipRoot`,
};

export const BreadcrumbsRoot = styled(Breadcrumbs)(({ theme }) => ({
  [`&.${MUIBreadcrumbsclasses.MUIBreadcrumbs}`]: {
    marginBottom: "20px",
    [`& .MuiBreadcrumbs-ol`]: {
      justifyContent: "end",
    },
    [`& .MuiBreadcrumbs-separator`]: {
      marginLeft: "4px",
      marginRight: "4px",
    },
  },
}));

export const ChipRoot = styled(Chip)(({ theme }) => ({
  [`&.${MUIBreadcrumbsclasses.ChipRoot}`]: {
    cursor: "pointer",
    backgroundColor: "#298D74",
    color: "#fff",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      height: "28px",
    },
    [`& svg`]: {
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.18rem",
      },
    },
  },
}));

export const MUIBreadcrumbs = () => {
  const history = useHistory();
  return (
    <>
      <BreadcrumbsRoot
        className={MUIBreadcrumbsclasses.MUIBreadcrumbs}
        aria-label="breadcrumb"
        separator={<KeyboardDoubleArrowLeftIcon fontSize="small" />}
      >
        <ChipRoot
          onClick={() => history.push("/dashboard")}
          className={MUIBreadcrumbsclasses.ChipRoot}
          label="Dashboard"
          icon={<HomeIcon fontSize="small" />}
        />
        <ChipRoot
          onClick={() => history.push("/vlb")}
          className={MUIBreadcrumbsclasses.ChipRoot}
          label="Load Balancer"
        />
      </BreadcrumbsRoot>
    </>
  );
};
