import {
  Box,
  Paper,
  Button,
  TextField,
  Grid,
  Typography,
  Checkbox,
  styled,
  useTheme,
} from "@mui/material";
import logo from "../../original_logo.svg";
import DividerWithText from "../common/divider/DividerWithText";
import GoogleSignInButton from "../common/buttons/GoogleSignInButton";
import { useDispatch, useSelector } from "react-redux";
import {
  signInWithGoogle,
  signinWithApple,
  signUpWithEmailAndPassword,
} from "../../store/actions/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import AppleSignInButton from "../common/buttons/AppleSignInButton";

const justifyCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const SignUpButton = styled(Button)({
  textTransform: "none",
  height: "40px",
  width: "100%",
});

function SignUp() {
  const theme = useTheme();
  const history = useHistory();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const authError = useSelector((state) => state.auth.authError);
  const { t } = useTranslation();

  const signUpError = (error) => {
    if (error.code === "auth/invalid-email")
      return toast.error(t("error.auth/invalid-email"));
    else if (error.code === "auth/email-already-in-use")
      return toast.error(t("error.auth/email-already-in-use"));
    else if (error.code === "auth/weak-password")
      return toast.error(t("error.auth/weak-password"));
  };

  function handleNameFieldChange(e) {
    setName(e.target.value);
  }

  function handleEmailFieldChange(e) {
    setIsValidEmail(validateEmail(e.target.value));
    setEmail(e.target.value);
  }

  function handlePasswordFieldChange(e) {
    setPassword(e.target.value);
  }

  function handleConfirmPasswordFieldChange(e) {
    setConfirmPassword(e.target.value);
  }

  function handleOnClickCheckbox(e) {
    setChecked(e.target.checked);
  }

  function createUserWithEmailAndPassword() {
    dispatch(
      signUpWithEmailAndPassword({
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      })
    );
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    if (auth.uid) {
      history.replace("/dashboard");
    }

    if (authError) {
      signUpError(authError);
      console.log(authError);
    }
  }, [auth.uid, authError, history]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: "650px",
          minWidth: "180px",
          margin: "10px auto",
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Paper sx={{ margin: "10px", padding: "20px" }} elevation={10}>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={justifyCenter}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "230px",
                  marginBottom: "30px",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <GoogleSignInButton
                title={t("button.signin_google")}
                onClick={() => {
                  dispatch(signInWithGoogle());
                }}
              />
            </Grid>
            <Grid item xs={12} sx={justifyCenter}>
              <AppleSignInButton
                title={t("button.signin_apple")}
                onClick={() => {
                  dispatch(signinWithApple());
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DividerWithText>
                {t("text.create_account_with_email_password")}
              </DividerWithText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Name"
                placeholder={t("text.name_placeholder")}
                variant="outlined"
                onChange={handleNameFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="email"
                variant="outlined"
                label={t("text.email")}
                placeholder="yourname@example.com"
                error={!isValidEmail}
                helperText={!isValidEmail ? "Please enter a valid email" : null}
                onChange={handleEmailFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                variant="outlined"
                label={t("text.password")}
                type="password"
                placeholder="********"
                error={authError === "The two passwords do not match"}
                onChange={handlePasswordFieldChange}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                variant="outlined"
                label={t("text.confirm_password")}
                type="password"
                placeholder="********"
                onChange={handleConfirmPasswordFieldChange}
                error={authError === "The two passwords do not match"}
                helperText={
                  authError === "The two passwords do not match"
                    ? "Die beiden Kennwörter stimmen nicht überein"
                    : null
                }
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                display="block"
                align="center"
                gutterBottom
              >
                <Checkbox
                  onChange={handleOnClickCheckbox}
                  checked={checked}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Trans i18nKey="text.agreement_checkbox_text">
                  I have read the
                  <a href="https://www.daheimladen.de/datenschutz">
                    privacy policy
                  </a>
                  and agree to the terms and conditions."
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SignUpButton
                color="primary"
                size="small"
                variant="contained"
                disableElevation={true}
                disabled={
                  !checked ||
                  name === "" ||
                  name === null ||
                  email === "" ||
                  email === null ||
                  password === "" ||
                  password === null ||
                  confirmPassword === "" ||
                  confirmPassword === null
                }
                onClick={createUserWithEmailAndPassword}
              >
                {t("button.signup_email")}
              </SignUpButton>
            </Grid>
          </Grid>
          <Toaster />
        </Paper>
      </Box>
    </div>
  );
}

export default SignUp;
